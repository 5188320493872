function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import { ButtonTypes } from "segments/desktop/grid/layouts/background-image-cards-grid/card-button/button-types";
import { PARAGRAPH_AND_ASSET_VIDEOS } from "constants/video-types";
import ThemeConfig from "segments/core-configs/theme-config";
const { mobileTextAlign, textColor, topic, buttonConfig } = FullParagraphFieldsConfig, resOfParagraphConfig = _object_without_properties(FullParagraphFieldsConfig, [
    "mobileTextAlign",
    "textColor",
    "topic",
    "buttonConfig"
]);
const button = {
    type: FIELD_TYPES.CONDITIONAL_NESTED,
    label: "Button",
    conditionalKeyName: "buttonType",
    options: [
        {
            text: ButtonTypes.PRODUCT_SIGNUP_AND_LEARN_MORE,
            fieldsConfig: {
                productType: {
                    label: "Product",
                    type: FIELD_TYPES.PRODUCTS
                },
                buttonColor: {
                    label: "Button color",
                    type: FIELD_TYPES.COLOR_PICKER
                },
                invert: {
                    label: "Invert",
                    type: FIELD_TYPES.BOOLEAN
                },
                withArrow: {
                    label: "With arrow",
                    type: FIELD_TYPES.BOOLEAN
                }
            },
            type: FIELD_TYPES.NESTED
        },
        {
            text: ButtonTypes.LABEL,
            fieldsConfig: {
                text: {
                    label: "Text",
                    type: FIELD_TYPES.TEXT
                },
                borderColor: {
                    label: "Border color",
                    type: FIELD_TYPES.COLOR_PICKER
                }
            },
            type: FIELD_TYPES.NESTED
        }
    ]
};
export default {
    type: FIELD_TYPES.NESTED,
    label: "Background Image Cards",
    fields: _object_spread_props(_object_spread({}, ThemeConfig), {
        cardHeight: {
            type: FIELD_TYPES.TEXT,
            label: "Card Height"
        },
        cardsGap: {
            type: FIELD_TYPES.TEXT,
            label: "Cards gap (default 12px)"
        },
        cards: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Cards",
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: {
                    backgroundImage: {
                        type: FIELD_TYPES.GALLERY,
                        label: "Fallback background image (if no video supplied)"
                    },
                    backgroundVideo: {
                        type: FIELD_TYPES.CLOUDINARY_VIDEO_PICKER,
                        label: "Background video (on hover)",
                        videoType: PARAGRAPH_AND_ASSET_VIDEOS
                    },
                    mobileBackgroundImage: {
                        type: FIELD_TYPES.GALLERY,
                        label: "Mobile image"
                    },
                    paragraph: {
                        type: FIELD_TYPES.NESTED,
                        label: "Paragraph",
                        fields: _object_spread({
                            topic: _object_spread_props(_object_spread({}, topic), {
                                fields: {
                                    image: topic.fields.image
                                }
                            })
                        }, resOfParagraphConfig)
                    },
                    button,
                    marginLeft: {
                        type: FIELD_TYPES.TEXT,
                        label: "Margin left"
                    },
                    marginRight: {
                        type: FIELD_TYPES.TEXT,
                        label: "Margin right"
                    },
                    marginTop: {
                        type: FIELD_TYPES.TEXT,
                        label: "Margin top"
                    },
                    marginBottom: {
                        type: FIELD_TYPES.TEXT,
                        label: "Margin bottom"
                    },
                    borderRadius: {
                        type: FIELD_TYPES.TEXT,
                        label: "Border radius"
                    }
                }
            }
        }
    })
};
