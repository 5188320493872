import { FIELD_TYPES } from "constants/template-field-type";
import Constants from "segments/desktop/grid/layouts/blog-cards-grid/constants";
export default {
    writerImageSrc: {
        type: FIELD_TYPES.GALLERY,
        defaultImagesKeys: [],
        label: `Select Writer Image (${Constants.WRITER_AVATAR_IMAGE_WIDTH}x${Constants.WRITER_AVATAR_IMAGE_HEIGHT})`
    },
    writerName: {
        type: FIELD_TYPES.TEXT,
        label: "Writer Name"
    },
    articleDate: {
        type: FIELD_TYPES.TEXT,
        label: "Article Date",
        translatable: true
    },
    minutesToRead: {
        type: FIELD_TYPES.TEXT,
        label: "Minutes",
        translatable: true
    }
};
