import { FIELD_TYPES } from "constants/template-field-type";
export default {
    tagText: {
        type: FIELD_TYPES.TEXT,
        label: "Tag Text"
    },
    tagColor: {
        type: FIELD_TYPES.COLOR_PICKER,
        label: "Tag Color"
    }
};
