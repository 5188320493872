function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import GridParagraphWithImageFieldsConfig from "segments/desktop/core-components/grid-paragraph-with-image/fields-config";
export default {
    type: FIELD_TYPES.NESTED,
    label: "Connected products",
    fields: {
        gridItems: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Grid Items",
            maxLimit: 4,
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: _object_spread({}, GridParagraphWithImageFieldsConfig)
            }
        }
    }
};
