import { FIELD_TYPES } from "constants/template-field-type";
export default {
    testimonial: {
        type: FIELD_TYPES.TEXT,
        label: "Testimonials",
        textArea: true,
        translatable: true
    },
    removeQuote: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Remove quote background"
    },
    quoteFontSize: {
        type: FIELD_TYPES.TEXT,
        label: "Testimonial font size"
    },
    quoteLineHeight: {
        type: FIELD_TYPES.TEXT,
        label: "Testimonial line height"
    }
};
