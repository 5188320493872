import styled from "@emotion/styled";
import { CANVAS_PREVIEW, REACT_CANVAS, SSR_CANVAS } from "constants/z-index";
export const StyledCanvas = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${REACT_CANVAS};

  &.ssr {
    z-index: ${SSR_CANVAS};
  }

  &.preview {
    z-index: ${CANVAS_PREVIEW};
  }
`;
