import { CrmIcon, DesignIcon, HrIcon, ItIcon, MarketingIcon, OperationsIcon, ProductIcon, ProjectsIcon, TasksIcon, SoftwareIcon } from "./icons/index.js";
import { CRM, DESIGN_AND_CREATIVE, HR, IT, MARKETING, OPERATIONS, PRODUCT_MANAGEMENT, SOFTWARE, TASK_MANAGEMENT } from "constants/clusters";
import { CRM_PRODUCT_ID, DEV_PRODUCT_ID, SERVICE_PRODUCT_ID, WORK_MANAGEMENT_PRODUCT_ID } from "constants/products";
const CLUSTER_TO_PRODUCT_MAP = {
    [SOFTWARE]: DEV_PRODUCT_ID,
    [IT]: SERVICE_PRODUCT_ID,
    [CRM]: CRM_PRODUCT_ID
};
const ICONS_NAMES = {
    PROJECTS: "Projects",
    TASKS: "Tasks",
    MARKETING: "Marketing",
    DESIGN: "Design",
    CRM: "CRM",
    PRODUCT: "Product",
    IT: "IT",
    OPERATIONS: "Operations",
    SOFTWARE: "Software",
    HR: "HR"
};
export const NAME_TO_ICON = {
    [ICONS_NAMES.PROJECTS]: ProjectsIcon,
    [ICONS_NAMES.TASKS]: TasksIcon,
    [ICONS_NAMES.MARKETING]: MarketingIcon,
    [ICONS_NAMES.DESIGN]: DesignIcon,
    [ICONS_NAMES.CRM]: CrmIcon,
    [ICONS_NAMES.PRODUCT]: ProductIcon,
    [ICONS_NAMES.IT]: ItIcon,
    [ICONS_NAMES.OPERATIONS]: OperationsIcon,
    [ICONS_NAMES.SOFTWARE]: SoftwareIcon,
    [ICONS_NAMES.HR]: HrIcon
};
export const CLUSTER_TO_TAG_ICON_MAP = {
    [SOFTWARE]: ProductIcon,
    [TASK_MANAGEMENT]: TasksIcon,
    [CRM]: CrmIcon,
    [HR]: HrIcon,
    [MARKETING]: MarketingIcon,
    [DESIGN_AND_CREATIVE]: DesignIcon,
    [IT]: ItIcon,
    [OPERATIONS]: OperationsIcon,
    [PRODUCT_MANAGEMENT]: ProjectsIcon
};
export const getClusterProduct = (cluster)=>CLUSTER_TO_PRODUCT_MAP[cluster] || null;
