import { FIELD_TYPES } from "constants/template-field-type";
import ReviewCardFieldsConfig from "segments/desktop/core-components/review-card/fields-config";
export default {
    reviews: {
        type: FIELD_TYPES.MULTIPLE_NESTED,
        label: "Reviews",
        maxLimit: 2,
        nestedConfig: {
            type: FIELD_TYPES.NESTED,
            fields: ReviewCardFieldsConfig
        }
    }
};
