import { FIELD_TYPES } from "constants/template-field-type";
import { PRODUCT_OPTIONS } from "constants/products";
export default {
    type: FIELD_TYPES.NESTED,
    label: "Whats New Items List",
    fields: {
        productFilterValue: {
            label: "Product Filter Value",
            type: FIELD_TYPES.COMBO,
            options: PRODUCT_OPTIONS
        },
        hideProductFilter: {
            label: "Hide Product Filter",
            type: FIELD_TYPES.BOOLEAN
        }
    }
};
