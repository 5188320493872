// plop_marker:layouts_import
import HotdogShape from "./hotdog-shape/index.js";
import RingShape from "./ring-shape/index.js";
import StarShape from "./star-shape/index.js";
import RectangleShape from "./rectangle-shape/index.js";
import SquareShape from "./square-shape/index.js";
export default {
    // plop_marker:layouts_add_layout
    HotdogShape,
    RingShape,
    StarShape,
    RectangleShape,
    SquareShape
};
