// plop_marker:layouts_import
import ProofAndBadgesEnterprise from "./proof-and-badges-enterprise/index.js";
import SalesPhoneEnterprise from "./sales-phone-enterprise/index.js";
import AsymmetricEnterprise from "./asymmetric-enterprise/index.js";
export default {
    // plop_marker:layouts_add_layout
    ProofAndBadgesEnterprise,
    SalesPhoneEnterprise,
    AsymmetricEnterprise
};
