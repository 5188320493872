import dynamic from "next/dynamic";
const WhatsNewItemsListEmbeddedComponent = dynamic(()=>import("./whats-new-items-list-embedded-component"));
import WhatsNewItemsListEmbeddedConfig from "./whats-new-items-list-embedded-config";
import WhatsNewItemsListEmbeddedAdvancedConfig from "./whats-new-items-list-embedded-advanced-config";
import WhatsNewItemsListEmbeddedDynamicsFetch from "./whats-new-items-list-embedded-dynamics-fetch";
export default {
    WhatsNewItemsListEmbeddedComponent,
    WhatsNewItemsListEmbeddedConfig,
    WhatsNewItemsListEmbeddedAdvancedConfig,
    WhatsNewItemsListEmbeddedDynamicsFetch
};
