import Layouts from "./layouts/index.js";
const { // plop_marker:segment_index_import_layout
WhatsNewItemsListEmbedded, MarkdownEmbedded } = Layouts;
export default {
    // plop_marker:segment_index_add_layout
    WhatsNewItemsListEmbeddedComponent: {
        type: "WhatsNewItemsListEmbeddedComponent",
        title: "Whats New Items List",
        imgSrc: "/static/img/segments/layouts/whats-new-items-list-embedded.png",
        config: WhatsNewItemsListEmbedded.WhatsNewItemsListEmbeddedConfig,
        advancedConfig: WhatsNewItemsListEmbedded.WhatsNewItemsListEmbeddedAdvancedConfig,
        component: WhatsNewItemsListEmbedded.WhatsNewItemsListEmbeddedComponent,
        dynamicsFetch: WhatsNewItemsListEmbedded.WhatsNewItemsListEmbeddedDynamicsFetch
    },
    MarkdownEmbeddedComponent: {
        type: "MarkdownEmbeddedComponent",
        title: "Markdown",
        imgSrc: "/static/img/segments/layouts/markdown-embedded.png",
        config: MarkdownEmbedded.MarkdownEmbeddedConfig,
        advancedConfig: MarkdownEmbedded.MarkdownEmbeddedAdvancedConfig,
        component: MarkdownEmbedded.MarkdownEmbeddedComponent
    }
};
