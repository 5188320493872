// plop_marker:layouts_import
import SideBySideWithParagraphsParagraph from "./side-by-side-with-paragraphs-paragraph/index.js";
import TwoParagraphsParagraph from "./two-paragraphs-paragraph/index.js";
import UniverseParagraph from "./universe-paragraph/index.js";
import SideBySideTitleParagraph from "./side-by-side-title-paragraph/index.js";
import RotatingTitleParagraph from "./rotating-title-paragraph/index.js";
import FullParagraph from "./full-paragraph/index.js";
import TitleOnlyParagraph from "./title-only-paragraph/index.js";
import FullWithBulletsParagraph from "./full-with-bullets-paragraph/index.js";
export default {
    // plop_marker:layouts_add_layout
    SideBySideWithParagraphsParagraph,
    TwoParagraphsParagraph,
    UniverseParagraph,
    SideBySideTitleParagraph,
    RotatingTitleParagraph,
    FullParagraph,
    TitleOnlyParagraph,
    FullWithBulletsParagraph
};
