import { FIELD_TYPES } from "constants/template-field-type";
export default {
    name: {
        type: FIELD_TYPES.TEXT,
        label: "Name"
    },
    jobDescription: {
        type: FIELD_TYPES.TEXT,
        label: "Job Description",
        textArea: true,
        translatable: true
    },
    color: {
        type: FIELD_TYPES.COLOR_PICKER,
        label: "Text Color"
    }
};
