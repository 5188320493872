function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
const { trackBigBrainEventOnServer } = require("../../bigbrain-tracking-service/bigbrain-tracking-service");
const { BaseDataProvider } = require("./base-data-provider");
const ProviderKeys = require('./providers-keys');
const PLATFORM_SERVICE_HOST = "https://auth.monday.com";
const MONDAY_SOLUTIONS_ENDPOINT = "monday_solutions";
const MONDAY_SOLUTIONS_FETCH_SUCCESS = "monday_solutions_fetch_success";
const MONDAY_SOLUTIONS_FETCH_ERROR = "monday_solutions_fetch_error";
const LOCALE_PARAM_NAME = "locale";
class SolutionsDataProvider extends BaseDataProvider {
    key() {
        const { localeId } = this.params();
        return `${ProviderKeys.SOLUTIONS_DATA}_locale_${localeId}`;
    }
    getData() {
        var _this = this;
        return _async_to_generator(function*() {
            const { localeId } = _this.params();
            try {
                const options = {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    method: "GET"
                };
                const url = new URL(`${MONDAY_SOLUTIONS_ENDPOINT}?${LOCALE_PARAM_NAME}=${localeId}`, PLATFORM_SERVICE_HOST).href;
                const res = yield fetch(url, options);
                if (res.status === 200) {
                    trackBigBrainEventOnServer(MONDAY_SOLUTIONS_FETCH_SUCCESS);
                    const response = yield res.json();
                    return response;
                } else {
                    throw new Error(res.statusText);
                }
            } catch (e) {
                trackBigBrainEventOnServer(MONDAY_SOLUTIONS_FETCH_ERROR, {
                    direct_object: e.message
                });
                console.error(e.message);
                throw e;
            }
        })();
    }
    constructor(params){
        super(params);
    }
}
module.exports = SolutionsDataProvider;
