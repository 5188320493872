import { FIELD_TYPES } from "constants/template-field-type";
export default {
    sharableLink: {
        type: FIELD_TYPES.TEXT,
        label: "Shareable link",
        labelDescription: "Copy link from board share inside the monday platform"
    },
    boardIdAndToken: {
        type: FIELD_TYPES.TEXT,
        label: "Board id and token",
        labelDescription: "Old prop please use 'sharable link' instead - this will be deleted soon"
    },
    tooltipText: {
        type: FIELD_TYPES.TEXT,
        label: "Tooltip text",
        translatable: true
    }
};
