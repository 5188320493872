import { FIELD_TYPES } from "constants/template-field-type";
import { SPACE_BLUE_GRADIANT_THEME_ID, SPACE_BLUE_THEME_ID } from "segments/segments-service";
export default {
    type: FIELD_TYPES.NESTED,
    label: "Welcome Back",
    fields: {
        theme: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Theme",
            possibleColors: [
                SPACE_BLUE_GRADIANT_THEME_ID,
                SPACE_BLUE_THEME_ID
            ]
        },
        introText: {
            type: FIELD_TYPES.TEXT,
            label: "Intro text"
        }
    }
};
