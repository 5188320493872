import Layouts from "./layouts/index.js";
const { // plop_marker:segment_index_import_layout
ProductCardsSideBySideTextAndAsset, WhatsNewInnerPageSideBySideTextAndAsset, YoutubeTextAndAsset, SideBySideWithIconsTextAndAsset, BoardTabTextAndAsset, YoutubeTwoThirdsTextAndAsset, AsymmetricSideBySideTextAndAsset, TestimonialsTextAndAsset, SideBySideWithBulletsTextAndAsset, TwoThirdsTextAndAsset, FullBackgroundTextAndAsset, SideBySideWithBackgroundTextAndAsset, SideBySideTextAndAsset } = Layouts;
export default {
    // plop_marker:segment_index_add_layout
    ProductCardsSideBySideTextAndAssetComponent: {
        type: "ProductCardsSideBySideTextAndAssetComponent",
        title: "Product Cards Side By Side",
        imgSrc: "/static/img/segments/layouts/product-cards-side-by-side-text-and-asset.png",
        config: ProductCardsSideBySideTextAndAsset.ProductCardsSideBySideTextAndAssetConfig,
        advancedConfig: ProductCardsSideBySideTextAndAsset.ProductCardsSideBySideTextAndAssetAdvancedConfig,
        component: ProductCardsSideBySideTextAndAsset.ProductCardsSideBySideTextAndAssetComponent
    },
    WhatsNewInnerPageSideBySideTextAndAssetComponent: {
        type: "WhatsNewInnerPageSideBySideTextAndAssetComponent",
        title: "Whats New Inner Page Side By Side",
        imgSrc: "/static/img/segments/layouts/whats-new-inner-page-side-by-side-text-and-asset.png",
        config: WhatsNewInnerPageSideBySideTextAndAsset.WhatsNewInnerPageSideBySideTextAndAssetConfig,
        advancedConfig: WhatsNewInnerPageSideBySideTextAndAsset.WhatsNewInnerPageSideBySideTextAndAssetAdvancedConfig,
        component: WhatsNewInnerPageSideBySideTextAndAsset.WhatsNewInnerPageSideBySideTextAndAssetComponent
    },
    YoutubeTextAndAssetComponent: {
        type: "YoutubeTextAndAssetComponent",
        title: "Youtube",
        imgSrc: "/static/img/segments/layouts/youtube-text-and-asset.png",
        config: YoutubeTextAndAsset.YoutubeTextAndAssetConfig,
        advancedConfig: YoutubeTextAndAsset.YoutubeTextAndAssetAdvancedConfig,
        component: YoutubeTextAndAsset.YoutubeTextAndAssetComponent
    },
    SideBySideWithIconsTextAndAssetComponent: {
        type: "SideBySideWithIconsTextAndAssetComponent",
        title: "Side By Side With Icons",
        imgSrc: "/static/img/segments/layouts/side-by-side-with-icons-text-and-asset.png",
        config: SideBySideWithIconsTextAndAsset.SideBySideWithIconsTextAndAssetConfig,
        advancedConfig: SideBySideWithIconsTextAndAsset.SideBySideWithIconsTextAndAssetAdvancedConfig,
        component: SideBySideWithIconsTextAndAsset.SideBySideWithIconsTextAndAssetComponent
    },
    BoardTabTextAndAssetComponent: {
        type: "BoardTabTextAndAssetComponent",
        title: "Board Tab",
        imgSrc: "/static/img/segments/layouts/board-tab-text-and-asset.png",
        config: BoardTabTextAndAsset.BoardTabTextAndAssetConfig,
        advancedConfig: BoardTabTextAndAsset.BoardTabTextAndAssetAdvancedConfig,
        component: BoardTabTextAndAsset.BoardTabTextAndAssetComponent
    },
    YoutubeTwoThirdsTextAndAssetComponent: {
        type: "YoutubeTwoThirdsTextAndAssetComponent",
        title: "Youtube Two Thirds",
        imgSrc: "/static/img/segments/layouts/youtube-two-thirds-text-and-asset.png",
        config: YoutubeTwoThirdsTextAndAsset.YoutubeTwoThirdsTextAndAssetConfig,
        advancedConfig: YoutubeTwoThirdsTextAndAsset.YoutubeTwoThirdsTextAndAssetAdvancedConfig,
        component: YoutubeTwoThirdsTextAndAsset.YoutubeTwoThirdsTextAndAssetComponent
    },
    AsymmetricSideBySideTextAndAssetComponent: {
        type: "AsymmetricSideBySideTextAndAssetComponent",
        title: "Asymmetric Side By Side",
        imgSrc: "/static/img/segments/layouts/asymmetric-side-by-side-text-and-asset.png",
        config: AsymmetricSideBySideTextAndAsset.AsymmetricSideBySideTextAndAssetConfig,
        advancedConfig: AsymmetricSideBySideTextAndAsset.AsymmetricSideBySideTextAndAssetAdvancedConfig,
        component: AsymmetricSideBySideTextAndAsset.AsymmetricSideBySideTextAndAssetComponent
    },
    TestimonialsTextAndAssetComponent: {
        type: "TestimonialsTextAndAssetComponent",
        title: "Testimonials",
        imgSrc: "/static/img/segments/layouts/testimonials-text-and-asset.png",
        config: TestimonialsTextAndAsset.TestimonialsTextAndAssetConfig,
        advancedConfig: TestimonialsTextAndAsset.TestimonialsTextAndAssetAdvancedConfig,
        component: TestimonialsTextAndAsset.TestimonialsTextAndAssetComponent
    },
    SideBySideWithBulletsTextAndAssetComponent: {
        type: "SideBySideWithBulletsTextAndAssetComponent",
        title: "Side By Side With Bullets",
        imgSrc: "/static/img/segments/layouts/side-by-side-with-bullets-text-and-asset.png",
        config: SideBySideWithBulletsTextAndAsset.SideBySideWithBulletsTextAndAssetConfig,
        advancedConfig: SideBySideWithBulletsTextAndAsset.SideBySideWithBulletsTextAndAssetAdvancedConfig,
        component: SideBySideWithBulletsTextAndAsset.SideBySideWithBulletsTextAndAssetComponent
    },
    TwoThirdsTextAndAssetComponent: {
        type: "TwoThirdsTextAndAssetComponent",
        title: "Two Thirds",
        imgSrc: "/static/img/segments/layouts/two-thirds-text-and-asset.png",
        config: TwoThirdsTextAndAsset.TwoThirdsTextAndAssetConfig,
        advancedConfig: TwoThirdsTextAndAsset.TwoThirdsTextAndAssetAdvancedConfig,
        component: TwoThirdsTextAndAsset.TwoThirdsTextAndAssetComponent
    },
    FullBackgroundTextAndAssetComponent: {
        type: "FullBackgroundTextAndAssetComponent",
        title: "Full Background",
        imgSrc: "/static/img/segments/layouts/full-background-text-and-asset.png",
        config: FullBackgroundTextAndAsset.FullBackgroundTextAndAssetConfig,
        advancedConfig: FullBackgroundTextAndAsset.FullBackgroundTextAndAssetAdvancedConfig,
        component: FullBackgroundTextAndAsset.FullBackgroundTextAndAssetComponent
    },
    SideBySideWithBackgroundTextAndAssetComponent: {
        type: "SideBySideWithBackgroundTextAndAssetComponent",
        title: "Side By Side With Background",
        imgSrc: "/static/img/segments/layouts/side-by-side-with-background-text-and-asset.png",
        config: SideBySideWithBackgroundTextAndAsset.SideBySideWithBackgroundTextAndAssetConfig,
        advancedConfig: SideBySideWithBackgroundTextAndAsset.SideBySideWithBackgroundTextAndAssetAdvancedConfig,
        component: SideBySideWithBackgroundTextAndAsset.SideBySideWithBackgroundTextAndAssetComponent
    },
    SideBySideTextAndAssetComponent: {
        type: "SideBySideTextAndAssetComponent",
        title: "Side By Side",
        imgSrc: "/static/img/segments/layouts/side-by-side-text-and-asset.png",
        config: SideBySideTextAndAsset.SideBySideTextAndAssetConfig,
        advancedConfig: SideBySideTextAndAsset.SideBySideTextAndAssetAdvancedConfig,
        component: SideBySideTextAndAsset.SideBySideTextAndAssetComponent
    }
};
