function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _extends() {
    _extends = Object.assign || function(target) {
        for(var i = 1; i < arguments.length; i++){
            var source = arguments[i];
            for(var key in source){
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }
        return target;
    };
    return _extends.apply(this, arguments);
}
function _object_destructuring_empty(o) {
    if (o === null || o === void 0) throw new TypeError("Cannot destructure " + o);
    return o;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import TitleConfig from "segments/desktop/core-components/title/fields-config";
import ParagraphConfig from "segments/desktop/core-components/paragraph/fields-config";
import ThemeConfig from "segments/core-configs/theme-config";
import FullParagraphFieldConfig from "segments/desktop/core-components/full-paragraph/fields-config";
const restOfTitleConfig = _extends({}, _object_destructuring_empty(TitleConfig));
const restOfParagraphConfig = _extends({}, _object_destructuring_empty(ParagraphConfig));
const { textAlign, mobileTextAlign, textColor, topic, buttonConfig: button } = FullParagraphFieldConfig, restFullParagraphFieldsConfig = _object_without_properties(FullParagraphFieldConfig, [
    "textAlign",
    "mobileTextAlign",
    "textColor",
    "topic",
    "buttonConfig"
]);
export default {
    type: FIELD_TYPES.NESTED,
    label: "Tags By Product",
    fields: _object_spread_props(_object_spread({}, ThemeConfig), {
        fullParagraph: {
            type: FIELD_TYPES.NESTED,
            label: "Full paragraph",
            fields: restFullParagraphFieldsConfig
        },
        subTitle: {
            type: FIELD_TYPES.NESTED,
            label: "Sub title",
            fields: TitleConfig
        },
        tabsPaddingTop: {
            type: FIELD_TYPES.TEXT,
            label: "Tabs padding top"
        },
        tabs: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Tabs",
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: {
                    productType: {
                        type: FIELD_TYPES.PRODUCTS,
                        label: "Product type"
                    },
                    title: {
                        type: FIELD_TYPES.NESTED,
                        label: "Tab title",
                        fields: _object_spread({
                            productImage: {
                                type: FIELD_TYPES.GALLERY,
                                label: "Product logo image"
                            },
                            selectedProductImage: {
                                type: FIELD_TYPES.GALLERY,
                                label: "Selected product logo image"
                            }
                        }, restOfTitleConfig, restOfParagraphConfig)
                    },
                    tabContent: {
                        type: FIELD_TYPES.NESTED,
                        label: "Tab content",
                        fields: {
                            clusters: {
                                type: FIELD_TYPES.MULTIPLE_NESTED,
                                label: "Clusters",
                                maxLimit: 9,
                                nestedConfig: {
                                    type: FIELD_TYPES.NESTED,
                                    fields: {
                                        text: {
                                            type: FIELD_TYPES.TEXT,
                                            label: "Text",
                                            translatable: true
                                        },
                                        image: {
                                            type: FIELD_TYPES.GALLERY,
                                            label: "Image"
                                        },
                                        colorName: {
                                            type: FIELD_TYPES.COLOR_PICKER,
                                            label: "Color"
                                        },
                                        clusterId: {
                                            type: FIELD_TYPES.CLUSTERS,
                                            label: "Cluster Id"
                                        },
                                        useCaseId: {
                                            type: FIELD_TYPES.SUB_CLUSTERS,
                                            label: "Usecase Id"
                                        },
                                        initiallySelected: {
                                            type: FIELD_TYPES.BOOLEAN,
                                            label: "Initially selected"
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        progressbarColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Progress Bar Color"
        },
        isV2: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Is V2"
        },
        excludeFromWMHighIntentExperienceTest: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Exclude from WN high intent test (don't use)"
        }
    })
};
