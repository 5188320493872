function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
const SolutionsDataProvider = require("../../services/data-service/providers/solutions-data-provider");
const { getCachedData } = require("../../services/data-service/providers/base-data-provider");
const getMondaySolutionData = function() {
    var _ref = _async_to_generator(function*(localeId) {
        const provider = new SolutionsDataProvider({
            localeId
        });
        const data = yield getCachedData(provider);
        return data;
    });
    return function getMondaySolutionData(localeId) {
        return _ref.apply(this, arguments);
    };
}();
const getMondaySolutionById = function() {
    var _ref = _async_to_generator(function*({ localeId, solutionId }) {
        const { solutions } = yield getMondaySolutionData(localeId);
        const solution = solutions.find(({ id })=>{
            return `${id}` === `${solutionId}`;
        });
        return solution;
    });
    return function getMondaySolutionById(_) {
        return _ref.apply(this, arguments);
    };
}();
module.exports = {
    getMondaySolutionData,
    getMondaySolutionById
};
