function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import paragraphAdvancedConfig from "segments/desktop/core-components/paragraph/advanced-fields-config";
import sideBySideTextAndAssetConfig from "segments/desktop/core-components/side-by-side-text-and-asset/fields-config";
import sideBySideTextAndAssetAdvencedConfig from "segments/desktop/core-components/side-by-side-text-and-asset/advanced-field-config";
import { FIELD_TYPES } from "constants/template-field-type";
import AssetFieldsConfig from "components/core/asset/fields-config";
import { SIGNUP_BUTTON_TYPE, SIGNUP_COMBO_BUTTONS_TYPE } from "segments/desktop/core-components/button//button-consts";
import fullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import sharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
export const selectTemplateConfig = {
    solutionId: {
        type: FIELD_TYPES.SOLUTION,
        label: "Template"
    },
    showTitle: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Show title"
    },
    showDescription: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Show description"
    },
    showImageShadow: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Show image shadow"
    },
    overrideTitle: {
        type: FIELD_TYPES.TEXT,
        label: "Override Default Template Title"
    },
    overrideDescription: {
        type: FIELD_TYPES.TEXT,
        label: "Override Default Template Description",
        textArea: true
    },
    overrideImage: {
        type: FIELD_TYPES.NESTED,
        label: "Override Default Image",
        fields: AssetFieldsConfig
    },
    topicText: {
        type: FIELD_TYPES.TEXT,
        label: "Topic"
    },
    buttonSelect: {
        type: FIELD_TYPES.CONDITIONAL_NESTED,
        label: "Button",
        conditionalKeyName: "buttonType",
        options: [
            {
                type: FIELD_TYPES.NESTED,
                text: SIGNUP_BUTTON_TYPE,
                fieldsConfig: {
                    signupButtonText: {
                        type: FIELD_TYPES.TEXT,
                        label: "Signup Button Text"
                    }
                }
            },
            {
                type: FIELD_TYPES.NESTED,
                text: SIGNUP_COMBO_BUTTONS_TYPE,
                fieldsConfig: {
                    signupButtonText: {
                        type: FIELD_TYPES.TEXT,
                        label: "Signup Button Text"
                    },
                    seconderyButtonText: {
                        type: FIELD_TYPES.TEXT,
                        label: "Secondery Button Text"
                    }
                }
            }
        ]
    }
};
export const advancedTemplateConfig = {
    textSize: _object_spread_props(_object_spread({}, paragraphAdvancedConfig.textSize), {
        label: "Template Description Text Size"
    }),
    titleSize: _object_spread_props(_object_spread({}, sideBySideTextAndAssetConfig.titleSize), {
        label: "Template Title Text Size"
    }),
    mobileTextAlign: fullParagraphFieldsConfig.mobileTextAlign,
    hideOnMobile: sharedAdvancedFieldsConfig.hideOnMobile,
    alignToLogo: _object_spread({}, sideBySideTextAndAssetAdvencedConfig.alignToLogo)
};
