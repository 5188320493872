import { FIELD_TYPES } from "constants/template-field-type";
export default {
    type: FIELD_TYPES.NESTED,
    label: "All Logos",
    fields: {
        theme: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Theme",
            possibleColors: [
                "riverstone",
                "gradiant-gray"
            ]
        }
    }
};
