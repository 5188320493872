import { FIELD_TYPES } from "constants/template-field-type";
import { ALIGN_TYPES, ALIGN_LABELS } from "constants/align-types";
export default {
    type: FIELD_TYPES.NESTED,
    label: "Ring",
    fields: {
        innerRadius: {
            type: FIELD_TYPES.TEXT,
            label: "Inner Radius"
        },
        outerRadius: {
            type: FIELD_TYPES.TEXT,
            label: "Outer Radius"
        },
        x: {
            type: FIELD_TYPES.TEXT,
            label: "X"
        },
        y: {
            type: FIELD_TYPES.TEXT,
            label: "Y"
        },
        scale: {
            type: FIELD_TYPES.TEXT,
            label: "Scale"
        },
        rotation: {
            type: FIELD_TYPES.TEXT,
            label: "Rotation (0°-360°)"
        },
        fill: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Fill Color"
        },
        gradientFill: {
            type: FIELD_TYPES.NESTED,
            label: "Linear gradient fill",
            fields: {
                useLinearGradientFill: {
                    type: FIELD_TYPES.BOOLEAN,
                    label: "Use linear gradient fill"
                },
                color: {
                    type: FIELD_TYPES.COLOR_PICKER,
                    label: "Color",
                    labelDescription: "Second color in the gradient to be mixed with the fill color"
                },
                x: {
                    type: FIELD_TYPES.TEXT,
                    label: "X"
                },
                y: {
                    type: FIELD_TYPES.TEXT,
                    label: "Y"
                }
            }
        },
        align: {
            type: FIELD_TYPES.COMBO,
            label: "Align",
            options: [
                {
                    value: ALIGN_TYPES.LEFT,
                    text: ALIGN_LABELS.LEFT
                },
                {
                    value: ALIGN_TYPES.CENTER,
                    text: ALIGN_LABELS.CENTER
                },
                {
                    value: ALIGN_TYPES.RIGHT,
                    text: ALIGN_LABELS.RIGHT
                }
            ]
        }
    }
};
