import { FIELD_TYPES } from "constants/template-field-type";
export default {
    name: {
        type: FIELD_TYPES.TEXT,
        label: "Person Name"
    },
    jobDescription: {
        type: FIELD_TYPES.TEXT,
        label: "Job Description",
        translatable: true
    },
    personImg: {
        type: FIELD_TYPES.NESTED,
        label: "Person Image",
        fields: {
            src: {
                type: FIELD_TYPES.GALLERY,
                defaultImagesKeys: [],
                label: "Select Person Image"
            },
            alt: {
                type: FIELD_TYPES.TEXT,
                label: "Image description (alt)"
            }
        }
    }
};
