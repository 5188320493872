import Layouts from "./layouts/index.js";
const { // plop_marker:segment_index_import_layout
MultiTemplatesTemplates, SingleTemplateTemplates } = Layouts;
export default {
    // plop_marker:segment_index_add_layout
    MultiTemplatesTemplatesComponent: {
        type: "MultiTemplatesTemplatesComponent",
        title: "Multi Templates",
        imgSrc: "/static/img/segments/layouts/multi-templates-templates.png",
        config: MultiTemplatesTemplates.MultiTemplatesTemplatesConfig,
        advancedConfig: MultiTemplatesTemplates.MultiTemplatesTemplatesAdvancedConfig,
        component: MultiTemplatesTemplates.MultiTemplatesTemplatesComponent,
        dynamicsFetch: MultiTemplatesTemplates.MultiTemplatesTemplatesDynamicsFetch
    },
    SingleTemplateTemplatesComponent: {
        type: "SingleTemplateTemplatesComponent",
        title: "Single Template",
        imgSrc: "/static/img/segments/layouts/single-template-templates.png",
        config: SingleTemplateTemplates.SingleTemplateTemplatesConfig,
        advancedConfig: SingleTemplateTemplates.SingleTemplateTemplatesAdvancedConfig,
        component: SingleTemplateTemplates.SingleTemplateTemplatesComponent,
        dynamicsFetch: SingleTemplateTemplates.SingleTemplateTemplatesDynamicsFetch
    }
};
