import dynamic from "next/dynamic";
import SingleTemplateTemplatesConfig from "./single-template-templates-config";
import SingleTemplateTemplatesAdvancedConfig from "./single-template-templates-advanced-config";
import SingleTemplateTemplatesDynamicsFetch from "./single-template-templates-dynamics-fetch";
const SingleTemplateTemplatesComponent = dynamic(()=>import("./single-template-templates-component"));
export default {
    SingleTemplateTemplatesComponent,
    SingleTemplateTemplatesConfig,
    SingleTemplateTemplatesAdvancedConfig,
    SingleTemplateTemplatesDynamicsFetch
};
