// plop_marker:layouts_import
import SideBySideParagraphAndTagsHomepageFirstFold from "./side-by-side-paragraph-and-tags-homepage-first-fold/index.js";
import PlatformInteractiveExperienceHomepageFirstFold from "./platform-interactive-experience-homepage-first-fold/index.js";
import TagsByProductHomepageFirstFold from "./tags-by-product-homepage-first-fold/index.js";
import ProductsByUseCasesHomepageFirstFold from "./products-by-use-cases-homepage-first-fold/index.js";
import MondayProductsHomepageFirstFold from "./monday-products-homepage-first-fold/index.js";
import FullParagraphAndTagsHomepageFirstFold from "./full-paragraph-and-tags-homepage-first-fold/index.js";
import LiveBoardHomepageFirstFold from "./live-board-homepage-first-fold/index.js";
import SignUpWithProductsCardsHomepageFirstFold from "./sign-up-with-products-cards-homepage-first-fold/index.js";
import WelcomeBackHomepageFirstFold from "./welcome-back-homepage-first-fold/index.js";
import MondayUniverseHomepageFirstFold from "./monday-universe-homepage-first-fold/index.js";
import ScrollParallaxHomepageFirstFold from "./scroll-parallax-homepage-first-fold/index.js";
import GlobeWithTopCtaHomepageFirstFold from "./globe-with-top-cta-homepage-first-fold/index.js";
import GlobeHomepageFirstFold from "./globe-homepage-first-fold/index.js";
import AnimatingBoardHomepageFirstFold from "./animating-board-homepage-first-fold/index.js";
export default {
    // plop_marker:layouts_add_layout
    SideBySideParagraphAndTagsHomepageFirstFold,
    PlatformInteractiveExperienceHomepageFirstFold,
    TagsByProductHomepageFirstFold,
    ProductsByUseCasesHomepageFirstFold,
    MondayProductsHomepageFirstFold,
    FullParagraphAndTagsHomepageFirstFold,
    LiveBoardHomepageFirstFold,
    SignUpWithProductsCardsHomepageFirstFold,
    WelcomeBackHomepageFirstFold,
    MondayUniverseHomepageFirstFold,
    ScrollParallaxHomepageFirstFold,
    GlobeWithTopCtaHomepageFirstFold,
    GlobeHomepageFirstFold,
    AnimatingBoardHomepageFirstFold
};
