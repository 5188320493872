import { FIELD_TYPES } from "constants/template-field-type";
import { LOGO_COMPANY_FIELDS, LOGO_COMPANY_COUNTRIES, SUPPORTED_LOGOS_NUMBER_OPTIONS } from "segments/desktop/core-components/logos/logos-const";
export default {
    numberOfCompanies: {
        type: FIELD_TYPES.COMBO,
        label: "Number Of Companies",
        options: SUPPORTED_LOGOS_NUMBER_OPTIONS
    },
    companyField: {
        type: FIELD_TYPES.COMBO,
        label: "Company Field",
        options: LOGO_COMPANY_FIELDS
    },
    country: {
        type: FIELD_TYPES.COMBO,
        label: "Country",
        options: LOGO_COMPANY_COUNTRIES
    },
    personalizeUsingVisitorCountry: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Personalize using visitor's country"
    },
    grayscale: {
        type: FIELD_TYPES.COMBO,
        label: "Color",
        options: [
            {
                value: true,
                text: "B&W"
            },
            {
                value: false,
                text: "Normal"
            }
        ]
    }
};
