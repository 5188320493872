import Layouts from "./layouts/index.js";
const { // plop_marker:segment_index_import_layout
CarouselSelectableProductCardsGrid, BackgroundImageCardsGrid, AnimatedIconsGrid, MarqueeCardsGrid, HoverableCardsGrid, MarqueeGrid, BadgesGrid, SlidingCardGrid, ConnectedProductsGrid, FlexibleCardsGrid, SquareTagsGrid, ProductCardsGrid, MasonryGrid, TagItemsGrid, IconWithParagraphGrid, SelectableTagsWithCarouselGrid, BlogCardsGrid, ButtonImageTagsGrid, ButtonTagsGrid, CardsGrid, TagsGrid, SelectableTagsGrid, ImageWithParagraphGrid, OnlyImageGrid, PressGrid } = Layouts;
export default {
    // plop_marker:segment_index_add_layout
    CarouselSelectableProductCardsGridComponent: {
        type: "CarouselSelectableProductCardsGridComponent",
        title: "Carousel Selectable Product Cards",
        imgSrc: "/static/img/segments/layouts/carousel-selectable-product-cards-grid.png",
        config: CarouselSelectableProductCardsGrid.CarouselSelectableProductCardsGridConfig,
        advancedConfig: CarouselSelectableProductCardsGrid.CarouselSelectableProductCardsGridAdvancedConfig,
        component: CarouselSelectableProductCardsGrid.CarouselSelectableProductCardsGridComponent
    },
    BackgroundImageCardsGridComponent: {
        type: "BackgroundImageCardsGridComponent",
        title: "Background Image Cards",
        imgSrc: "/static/img/segments/layouts/background-image-cards-grid.png",
        config: BackgroundImageCardsGrid.BackgroundImageCardsGridConfig,
        advancedConfig: BackgroundImageCardsGrid.BackgroundImageCardsGridAdvancedConfig,
        component: BackgroundImageCardsGrid.BackgroundImageCardsGridComponent
    },
    AnimatedIconsGridComponent: {
        type: "AnimatedIconsGridComponent",
        title: "Animated Icons",
        imgSrc: "/static/img/segments/layouts/animated-icons-grid.png",
        config: AnimatedIconsGrid.AnimatedIconsGridConfig,
        advancedConfig: AnimatedIconsGrid.AnimatedIconsGridAdvancedConfig,
        component: AnimatedIconsGrid.AnimatedIconsGridComponent
    },
    MarqueeCardsGridComponent: {
        type: "MarqueeCardsGridComponent",
        title: "Marquee Cards",
        imgSrc: "/static/img/segments/layouts/marquee-cards-grid.png",
        config: MarqueeCardsGrid.MarqueeCardsGridConfig,
        advancedConfig: MarqueeCardsGrid.MarqueeCardsGridAdvancedConfig,
        component: MarqueeCardsGrid.MarqueeCardsGridComponent
    },
    HoverableCardsGridComponent: {
        type: "HoverableCardsGridComponent",
        title: "Hoverable Cards",
        imgSrc: "/static/img/segments/layouts/hoverable-cards-grid.png",
        config: HoverableCardsGrid.HoverableCardsGridConfig,
        advancedConfig: HoverableCardsGrid.HoverableCardsGridAdvancedConfig,
        component: HoverableCardsGrid.HoverableCardsGridComponent
    },
    MarqueeGridComponent: {
        type: "MarqueeGridComponent",
        title: "Marquee",
        imgSrc: "/static/img/segments/layouts/marquee-grid.png",
        config: MarqueeGrid.MarqueeGridConfig,
        advancedConfig: MarqueeGrid.MarqueeGridAdvancedConfig,
        component: MarqueeGrid.MarqueeGridComponent
    },
    BadgesGridComponent: {
        type: "BadgesGridComponent",
        title: "Badges",
        imgSrc: "/static/img/segments/layouts/badges-grid.png",
        config: BadgesGrid.BadgesGridConfig,
        advancedConfig: BadgesGrid.BadgesGridAdvancedConfig,
        component: BadgesGrid.BadgesGridComponent
    },
    SlidingCardGridComponent: {
        type: "SlidingCardGridComponent",
        title: "Sliding Card",
        imgSrc: "/static/img/segments/layouts/sliding-card-grid.png",
        config: SlidingCardGrid.SlidingCardGridConfig,
        advancedConfig: SlidingCardGrid.SlidingCardGridAdvancedConfig,
        component: SlidingCardGrid.SlidingCardGridComponent
    },
    PressGridComponent: {
        type: "PressGridComponent",
        title: "Press",
        imgSrc: "/static/img/segments/layouts/press-grid.png",
        config: PressGrid.PressGridConfig,
        advancedConfig: PressGrid.PressGridAdvancedConfig,
        component: PressGrid.PressGridComponent
    },
    ConnectedProductsGridComponent: {
        type: "ConnectedProductsGridComponent",
        title: "Connected Products",
        imgSrc: "/static/img/segments/layouts/connected-products-grid.png",
        config: ConnectedProductsGrid.ConnectedProductsGridConfig,
        advancedConfig: ConnectedProductsGrid.ConnectedProductsGridAdvancedConfig,
        component: ConnectedProductsGrid.ConnectedProductsGridComponent
    },
    FlexibleCardsGridComponent: {
        type: "FlexibleCardsGridComponent",
        title: "Flexible Cards",
        imgSrc: "/static/img/segments/layouts/flexible-cards-grid.png",
        config: FlexibleCardsGrid.FlexibleCardsGridConfig,
        advancedConfig: FlexibleCardsGrid.FlexibleCardsGridAdvancedConfig,
        component: FlexibleCardsGrid.FlexibleCardsGridComponent
    },
    SquareTagsGridComponent: {
        type: "SquareTagsGridComponent",
        title: "Square Tags",
        imgSrc: "/static/img/segments/layouts/square-tags-grid.png",
        config: SquareTagsGrid.SquareTagsGridConfig,
        advancedConfig: SquareTagsGrid.SquareTagsGridAdvancedConfig,
        component: SquareTagsGrid.SquareTagsGridComponent
    },
    ProductCardsGridComponent: {
        type: "ProductCardsGridComponent",
        title: "Product Cards",
        imgSrc: "/static/img/segments/layouts/product-cards-grid.png",
        config: ProductCardsGrid.ProductCardsGridConfig,
        advancedConfig: ProductCardsGrid.ProductCardsGridAdvancedConfig,
        component: ProductCardsGrid.ProductCardsGridComponent
    },
    MasonryGridComponent: {
        type: "MasonryGridComponent",
        title: "Masonry",
        imgSrc: "/static/img/segments/layouts/masonry-grid.png",
        config: MasonryGrid.MasonryGridConfig,
        advancedConfig: MasonryGrid.MasonryGridAdvancedConfig,
        component: MasonryGrid.MasonryGridComponent
    },
    TagItemsGridComponent: {
        type: "TagItemsGridComponent",
        title: "Tag Items",
        imgSrc: "/static/img/segments/layouts/tag-items-grid.png",
        config: TagItemsGrid.TagItemsGridConfig,
        advancedConfig: TagItemsGrid.TagItemsGridAdvancedConfig,
        component: TagItemsGrid.TagItemsGridComponent
    },
    IconWithParagraphGridComponent: {
        type: "IconWithParagraphGridComponent",
        title: "Icon With Paragraph",
        imgSrc: "/static/img/segments/layouts/icon-with-paragraph-grid.png",
        config: IconWithParagraphGrid.IconWithParagraphGridConfig,
        advancedConfig: IconWithParagraphGrid.IconWithParagraphGridAdvancedConfig,
        component: IconWithParagraphGrid.IconWithParagraphGridComponent
    },
    SelectableTagsWithCarouselGridComponent: {
        type: "SelectableTagsWithCarouselGridComponent",
        title: "Selectable Tags With Carousel",
        imgSrc: "/static/img/segments/layouts/selectable-tags-with-carousel-grid.png",
        config: SelectableTagsWithCarouselGrid.SelectableTagsWithCarouselGridConfig,
        advancedConfig: SelectableTagsWithCarouselGrid.SelectableTagsWithCarouselGridAdvancedConfig,
        component: SelectableTagsWithCarouselGrid.SelectableTagsWithCarouselGridComponent
    },
    BlogCardsGridComponent: {
        type: "BlogCardsGridComponent",
        title: "Blog Cards",
        imgSrc: "/static/img/segments/layouts/blog-cards-grid.png",
        config: BlogCardsGrid.BlogCardsGridConfig,
        advancedConfig: BlogCardsGrid.BlogCardsGridAdvancedConfig,
        component: BlogCardsGrid.BlogCardsGridComponent
    },
    ButtonImageTagsGridComponent: {
        type: "ButtonImageTagsGridComponent",
        title: "Button Image Tags",
        imgSrc: "/static/img/segments/layouts/button-image-tags-grid.png",
        config: ButtonImageTagsGrid.ButtonImageTagsGridConfig,
        advancedConfig: ButtonImageTagsGrid.ButtonImageTagsGridAdvancedConfig,
        component: ButtonImageTagsGrid.ButtonImageTagsGridComponent
    },
    ButtonTagsGridComponent: {
        type: "ButtonTagsGridComponent",
        title: "Button Tags",
        imgSrc: "/static/img/segments/layouts/button-tags-grid.png",
        config: ButtonTagsGrid.ButtonTagsGridConfig,
        advancedConfig: ButtonTagsGrid.ButtonTagsGridAdvancedConfig,
        component: ButtonTagsGrid.ButtonTagsGridComponent
    },
    CardsGridComponent: {
        type: "CardsGridComponent",
        title: "Cards",
        imgSrc: "/static/img/segments/layouts/cards-grid.png",
        config: CardsGrid.CardsGridConfig,
        advancedConfig: CardsGrid.CardsGridAdvancedConfig,
        component: CardsGrid.CardsGridComponent
    },
    TagsGridComponent: {
        type: "TagsGridComponent",
        title: "Tags",
        imgSrc: "/static/img/segments/layouts/tags-grid.png",
        config: TagsGrid.TagsGridConfig,
        advancedConfig: TagsGrid.TagsGridAdvancedConfig,
        component: TagsGrid.TagsGridComponent
    },
    SelectableTagsGridComponent: {
        type: "SelectableTagsGridComponent",
        title: "Selectable Tags Grid",
        imgSrc: "/static/img/segments/layouts/selectable-tags-grid.png",
        config: SelectableTagsGrid.SelectableTagsGridConfig,
        advancedConfig: SelectableTagsGrid.SelectableTagsGridAdvancedConfig,
        component: SelectableTagsGrid.SelectableTagsGridComponent
    },
    ImageWithParagraphGridComponent: {
        type: "ImageWithParagraphGridComponent",
        title: "Image With Paragraph",
        imgSrc: "/static/img/segments/layouts/image-with-paragraph-grid.png",
        config: ImageWithParagraphGrid.ImageWithParagraphGridConfig,
        advancedConfig: ImageWithParagraphGrid.ImageWithParagraphGridAdvancedConfig,
        component: ImageWithParagraphGrid.ImageWithParagraphGridComponent
    },
    OnlyImageGridComponent: {
        type: "OnlyImageGridComponent",
        title: "Only Image",
        imgSrc: "/static/img/segments/layouts/only-image-grid.png",
        config: OnlyImageGrid.OnlyImageGridConfig,
        advancedConfig: OnlyImageGrid.OnlyImageGridAdvancedConfig,
        component: OnlyImageGrid.OnlyImageGridComponent
    }
};
