import Layouts from "./layouts/index.js";
const { // plop_marker:segment_index_import_layout
ProofAndBadgesEnterprise, SalesPhoneEnterprise, AsymmetricEnterprise } = Layouts;
export default {
    // plop_marker:segment_index_add_layout
    ProofAndBadgesEnterpriseComponent: {
        type: "ProofAndBadgesEnterpriseComponent",
        title: "Proof And Badges",
        imgSrc: "/static/img/segments/layouts/proof-and-badges-enterprise.png",
        config: ProofAndBadgesEnterprise.ProofAndBadgesEnterpriseConfig,
        advancedConfig: ProofAndBadgesEnterprise.ProofAndBadgesEnterpriseAdvancedConfig,
        component: ProofAndBadgesEnterprise.ProofAndBadgesEnterpriseComponent
    },
    SalesPhoneEnterpriseComponent: {
        type: "SalesPhoneEnterpriseComponent",
        title: "Sales Phone",
        imgSrc: "/static/img/segments/layouts/sales-phone-enterprise.png",
        config: SalesPhoneEnterprise.SalesPhoneEnterpriseConfig,
        advancedConfig: SalesPhoneEnterprise.SalesPhoneEnterpriseAdvancedConfig,
        component: SalesPhoneEnterprise.SalesPhoneEnterpriseComponent
    },
    AsymmetricEnterpriseComponent: {
        type: "AsymmetricEnterpriseComponent",
        title: "Asymmetric",
        imgSrc: "/static/img/segments/layouts/asymmetric-enterprise.png",
        config: AsymmetricEnterprise.AsymmetricEnterpriseConfig,
        advancedConfig: AsymmetricEnterprise.AsymmetricEnterpriseAdvancedConfig,
        component: AsymmetricEnterprise.AsymmetricEnterpriseComponent
    }
};
