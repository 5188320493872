function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import AssetFieldsConfig from "components/core/asset/fields-config";
import { PROJECTS_ORANGE_COLOR_NAME, MARKETING_RED_COLOR_NAME, CRM_GREEN_COLOR_NAME, DEV_GREEN_COLOR_NAME, WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
export default {
    tabs: {
        type: FIELD_TYPES.MULTIPLE_NESTED,
        label: "Tabs",
        maxLimit: 5,
        nestedConfig: {
            type: FIELD_TYPES.NESTED,
            fields: {
                label: {
                    type: FIELD_TYPES.TEXT,
                    label: "Tab Label",
                    translatable: true
                },
                isMainTab: {
                    type: FIELD_TYPES.BOOLEAN,
                    label: "Main Work-OS tab"
                },
                color: {
                    type: FIELD_TYPES.COLOR_PICKER,
                    label: "Color"
                },
                tabImage: {
                    type: FIELD_TYPES.GALLERY,
                    defaultImagesKeys: [
                        "static/img/templates/long-template/boards/"
                    ],
                    label: "Tab image"
                },
                tabImageSelected: {
                    type: FIELD_TYPES.GALLERY,
                    defaultImagesKeys: [
                        "static/img/templates/long-template/boards/"
                    ],
                    label: "Tab Hover image"
                },
                asset: {
                    type: FIELD_TYPES.NESTED,
                    label: "Asset",
                    fields: _object_spread({}, AssetFieldsConfig)
                }
            }
        }
    }
};
