import Layouts from "./layouts/index.js";
const { // plop_marker:segment_index_import_layout
MondayIntegrationsConnections, FloatingIntegrations } = Layouts;
export default {
    // plop_marker:segment_index_add_layout
    MondayIntegrationsConnectionsComponent: {
        type: "MondayIntegrationsConnectionsComponent",
        title: "Monday Integrations",
        imgSrc: "/static/img/segments/layouts/monday-integrations-connections.png",
        config: MondayIntegrationsConnections.MondayIntegrationsConnectionsConfig,
        advancedConfig: MondayIntegrationsConnections.MondayIntegrationsConnectionsAdvancedConfig,
        component: MondayIntegrationsConnections.MondayIntegrationsConnectionsComponent
    },
    FloatingIntegrationsComponent: {
        type: "FloatingIntegrationsComponent",
        title: "Floating",
        imgSrc: "/static/img/segments/layouts/floating-integrations.png",
        config: FloatingIntegrations.FloatingIntegrationsConfig,
        advancedConfig: FloatingIntegrations.FloatingIntegrationsAdvancedConfig,
        component: FloatingIntegrations.FloatingIntegrationsComponent
    }
};
