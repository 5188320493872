import { FIELD_TYPES } from "/constants/template-field-type";
import secondaryButtonFieldsConfig from "segments/desktop/core-components/button/secondary-button/fields-config";
import youTubeButtonFieldsConfig from "segments/desktop/core-components/button/youtube-popup-button/fields-config";
export default {
    withSecondaryButton: {
        type: FIELD_TYPES.BOOLEAN,
        label: "With secondary button"
    },
    secondaryButtonConfig: {
        type: FIELD_TYPES.NESTED,
        label: "Secondary button",
        fields: secondaryButtonFieldsConfig
    },
    withYouTubeButton: {
        type: FIELD_TYPES.BOOLEAN,
        label: "With youtube button"
    },
    youTubeButtonConfig: {
        type: FIELD_TYPES.NESTED,
        label: "YouTube button",
        fields: youTubeButtonFieldsConfig
    },
    useMobileClusterTags: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Use mobile cluster tags"
    },
    sendClustersAsParams: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Send clusters as params"
    },
    clusterVariant: {
        type: FIELD_TYPES.TEXT,
        label: "Cluster variant (with_benefits / with_ready)"
    }
};
