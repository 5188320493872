function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import TwoThirdsTextAndAssetConfig from "segments/desktop/text-and-asset/layouts/two-thirds-text-and-asset/two-thirds-text-and-asset-config";
import bulletsConfig from "segments/desktop/core-components/bullets/fields-config";
export default {
    type: FIELD_TYPES.NESTED,
    label: "Regular",
    fields: {
        theme: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Theme"
        },
        tabsBackgroundColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Tabs background color"
        },
        tabsColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Tabs color"
        },
        tabs: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Tabs",
            maxLimit: 8,
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: {
                    tabLabel: {
                        type: FIELD_TYPES.TEXT,
                        label: "Tab Label",
                        translatable: true
                    },
                    tabId: {
                        type: FIELD_TYPES.TEXT,
                        label: "Tab Id"
                    },
                    textAndAsset: {
                        type: FIELD_TYPES.NESTED,
                        label: "Text and asset",
                        fields: _object_spread_props(_object_spread({}, TwoThirdsTextAndAssetConfig.fields), {
                            bulletsConfig
                        })
                    }
                }
            }
        }
    }
};
