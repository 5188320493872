function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import gridImageFieldsConfig from "segments/desktop/core-components/grid-image/fields-config";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import { XS, SM, MD, LG, LGR, XL, XXL, MS } from "constants/sizes";
const { textColor, textAlign } = FullParagraphFieldsConfig, selectedFullParagraphFields = _object_without_properties(FullParagraphFieldsConfig, [
    "textColor",
    "textAlign"
]);
export default {
    type: FIELD_TYPES.NESTED,
    label: "Icon With Paragraph",
    fields: {
        iconWidth: {
            type: FIELD_TYPES.TEXT,
            label: "Icon width"
        },
        iconHeight: {
            type: FIELD_TYPES.TEXT,
            label: "Icon height"
        },
        titleSize: {
            type: FIELD_TYPES.COMBO,
            label: "Title Size",
            options: [
                {
                    value: XS,
                    text: "24px"
                },
                {
                    value: SM,
                    text: "32px"
                },
                {
                    value: MD,
                    text: "40px"
                },
                {
                    value: LG,
                    text: "50px"
                },
                {
                    value: LGR,
                    text: "60px"
                },
                {
                    value: XL,
                    text: "72px"
                },
                {
                    value: XXL,
                    text: "80px"
                }
            ]
        },
        textSize: {
            type: FIELD_TYPES.COMBO,
            label: "Body Size",
            options: [
                {
                    value: MS,
                    text: "16px"
                },
                {
                    value: MD,
                    text: "20px"
                },
                {
                    value: LG,
                    text: "24px"
                }
            ]
        },
        imagesGap: {
            type: FIELD_TYPES.TEXT,
            label: "Images gap"
        },
        images: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Icons",
            maxLimit: 4,
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: _object_spread_props(_object_spread({}, gridImageFieldsConfig), {
                    fullParagraph: {
                        type: FIELD_TYPES.NESTED,
                        label: "Full Paragraph",
                        fields: selectedFullParagraphFields
                    }
                })
            }
        }
    }
};
