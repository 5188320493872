import dynamic from "next/dynamic";
import MultiTemplatesTemplatesConfig from "./multi-templates-templates-config";
import MultiTemplatesTemplatesAdvancedConfig from "./multi-templates-templates-advanced-config";
import MultiTemplatesTemplatesDynamicsFetch from "./multi-templates-templates-dynamics-fetch";
const MultiTemplatesTemplatesComponent = dynamic(()=>import("./multi-templates-templates-component"));
export default {
    MultiTemplatesTemplatesComponent,
    MultiTemplatesTemplatesConfig,
    MultiTemplatesTemplatesAdvancedConfig,
    MultiTemplatesTemplatesDynamicsFetch
};
