function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _extends() {
    _extends = Object.assign || function(target) {
        for(var i = 1; i < arguments.length; i++){
            var source = arguments[i];
            for(var key in source){
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }
        return target;
    };
    return _extends.apply(this, arguments);
}
function _object_destructuring_empty(o) {
    if (o === null || o === void 0) throw new TypeError("Cannot destructure " + o);
    return o;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import TitleConfig from "segments/desktop/core-components/title/fields-config";
import ParagraphConfig from "segments/desktop/core-components/paragraph/fields-config";
import ThemeConfig from "segments/core-configs/theme-config";
import AssetFieldsConfig from "components/core/asset/fields-config";
const restOfTitleConfig = _extends({}, _object_destructuring_empty(TitleConfig));
const restOfParagraphConfig = _extends({}, _object_destructuring_empty(ParagraphConfig));
export default {
    type: FIELD_TYPES.NESTED,
    label: "Products Horizontal Timer",
    fields: _object_spread_props(_object_spread({}, ThemeConfig), {
        tabsTitle: {
            type: FIELD_TYPES.NESTED,
            label: "Tabs Title",
            fields: {
                titleText: {
                    type: FIELD_TYPES.TEXT,
                    label: "Title"
                },
                titleMarginBottom: {
                    type: FIELD_TYPES.TEXT,
                    label: "Title margin bottom"
                }
            }
        },
        tabs: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Tabs",
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: {
                    productType: {
                        type: FIELD_TYPES.PRODUCTS,
                        label: "Product type"
                    },
                    title: {
                        type: FIELD_TYPES.NESTED,
                        label: "Tab title",
                        fields: _object_spread({
                            productImage: {
                                type: FIELD_TYPES.GALLERY,
                                label: "Product logo image"
                            },
                            selectedProductImage: {
                                type: FIELD_TYPES.GALLERY,
                                label: "Selected product logo image"
                            }
                        }, restOfTitleConfig, restOfParagraphConfig)
                    },
                    tabContent: {
                        type: FIELD_TYPES.NESTED,
                        label: "Tab content",
                        fields: {
                            productTags: {
                                type: FIELD_TYPES.MULTIPLE_NESTED,
                                label: "Product tags",
                                nestedConfig: {
                                    type: FIELD_TYPES.NESTED,
                                    fields: {
                                        text: {
                                            type: FIELD_TYPES.TEXT,
                                            label: "Tag"
                                        }
                                    }
                                }
                            },
                            asset: {
                                type: FIELD_TYPES.NESTED,
                                label: "Asset",
                                fields: AssetFieldsConfig
                            }
                        }
                    }
                }
            }
        },
        progressbarColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Progress Bar Color"
        }
    })
};
