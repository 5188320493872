function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import FullParagraphAdvancedFieldsConfig from "segments/desktop/core-components/full-paragraph/advanced-fields-config";
import assetFieldsConfig from "components/core/asset/fields-config";
export default {
    type: FIELD_TYPES.NESTED,
    label: "",
    fields: _object_spread_props(_object_spread({}, SharedAdvancedFieldsConfig), {
        tagsTitleFontSize: {
            type: FIELD_TYPES.COMBO,
            label: "Tags title text size",
            options: [
                {
                    value: 14,
                    text: "14px"
                },
                {
                    value: 16,
                    text: "16px (default)"
                },
                {
                    value: 18,
                    text: "18px"
                },
                {
                    value: 20,
                    text: "20px"
                },
                {
                    value: 22,
                    text: "22px"
                }
            ]
        },
        tagsTitleAlignment: {
            type: FIELD_TYPES.COMBO,
            label: "Tags title alignment",
            options: [
                {
                    value: "left",
                    text: "Left"
                },
                {
                    value: "center",
                    text: "Center"
                },
                {
                    value: "right",
                    text: "Right"
                }
            ]
        },
        fullParagraphAdvancedConfig: {
            type: FIELD_TYPES.NESTED,
            label: "Paragraph Advanced Config",
            fields: FullParagraphAdvancedFieldsConfig
        },
        fullParagraphTopMargin: {
            type: FIELD_TYPES.TEXT,
            label: "Full Paragraph top margin"
        },
        defaultImage: {
            type: FIELD_TYPES.NESTED,
            label: "Tag default Image",
            fields: _object_spread({}, assetFieldsConfig)
        },
        blurDefaultImage: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Blur default image"
        },
        minHeight: {
            type: FIELD_TYPES.TEXT,
            label: "Min height"
        },
        showSelectedProduct: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Show latest selected product"
        },
        flipUseCasesAndAssets: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Flip use cases and assets"
        },
        verticalView: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Vertical view"
        },
        imageMaxWidth: {
            type: FIELD_TYPES.TEXT,
            label: "Image max width (default is 900px)"
        },
        useCasesTopMargin: {
            type: FIELD_TYPES.TEXT,
            label: "Use cases top margin"
        },
        resizingFactor: {
            type: FIELD_TYPES.TEXT,
            label: "Resizing factor"
        },
        assetsAndTagsExtraSpace: {
            type: FIELD_TYPES.TEXT,
            label: "Assets and tags extra space (default is 10vw)"
        },
        startAtCenter: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Start at center"
        },
        enabledDefaultImageAnimation: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Enable default image animation"
        }
    })
};
