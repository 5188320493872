function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import AssetConfig from "/components/core/asset/fields-config";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
const { video } = AssetConfig;
const { topic, buttonConfig, mobileTextAlign, textAlign, textColor } = FullParagraphFieldsConfig, restOfConfig = _object_without_properties(FullParagraphFieldsConfig, [
    "topic",
    "buttonConfig",
    "mobileTextAlign",
    "textAlign",
    "textColor"
]);
export default {
    type: FIELD_TYPES.NESTED,
    label: "Parallax Video",
    fields: {
        asset: video,
        scrollThreshold: {
            type: FIELD_TYPES.TEXT,
            label: "Parallax scroll threshold"
        },
        marginBetweenSections: {
            type: FIELD_TYPES.TEXT,
            label: "Margin between sections"
        },
        marginBetweenColumns: {
            type: FIELD_TYPES.TEXT,
            label: "Minimum margin between columns"
        },
        leftColumn: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Left column",
            maxLimit: 3,
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: restOfConfig
            }
        },
        rightColumn: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Right column",
            maxLimit: 3,
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: restOfConfig
            }
        },
        columnMarginLeft: {
            type: FIELD_TYPES.TEXT,
            label: "Column margin left"
        },
        columnMarginRight: {
            type: FIELD_TYPES.TEXT,
            label: "Column margin right"
        }
    }
};
