import Layouts from "./layouts/index.js";
const { // plop_marker:segment_index_import_layout
MultiProductsPricing, FeaturesTablePricing, PricesAndFeaturesCardsPricing, FrequentlyAskedQuestionsPricing } = Layouts;
export default {
    // plop_marker:segment_index_add_layout
    MultiProductsPricingComponent: {
        type: "MultiProductsPricingComponent",
        title: "Multi Products Pricing",
        imgSrc: "/static/img/segments/layouts/multi-products-pricing.png",
        config: MultiProductsPricing.MultiProductsPricingConfig,
        advancedConfig: MultiProductsPricing.MultiProductsPricingAdvancedConfig,
        component: MultiProductsPricing.MultiProductsPricingComponent
    },
    FeaturesTablePricingComponent: {
        type: "FeaturesTablePricingComponent",
        title: "Features Table",
        imgSrc: "/static/img/segments/layouts/features-table-pricing.png",
        config: FeaturesTablePricing.FeaturesTablePricingConfig,
        advancedConfig: FeaturesTablePricing.FeaturesTablePricingAdvancedConfig,
        component: FeaturesTablePricing.FeaturesTablePricingComponent
    },
    PricesAndFeaturesCardsPricingComponent: {
        type: "PricesAndFeaturesCardsPricingComponent",
        title: "Prices And Features Cards",
        imgSrc: "/static/img/segments/layouts/prices-and-features-cards-pricing.png",
        config: PricesAndFeaturesCardsPricing.PricesAndFeaturesCardsPricingConfig,
        advancedConfig: PricesAndFeaturesCardsPricing.PricesAndFeaturesCardsPricingAdvancedConfig,
        component: PricesAndFeaturesCardsPricing.PricesAndFeaturesCardsPricingComponent
    },
    FrequentlyAskedQuestionsPricingComponent: {
        type: "FrequentlyAskedQuestionsPricingComponent",
        title: "Frequently Asked Questions",
        imgSrc: "/static/img/segments/layouts/frequently-asked-questions-pricing.png",
        config: FrequentlyAskedQuestionsPricing.FrequentlyAskedQuestionsPricingConfig,
        advancedConfig: FrequentlyAskedQuestionsPricing.FrequentlyAskedQuestionsPricingAdvancedConfig,
        component: FrequentlyAskedQuestionsPricing.FrequentlyAskedQuestionsPricingComponent
    }
};
