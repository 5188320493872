// plop_marker:layouts_import
import LogosAndParagraphLogos from "./logos-and-paragraph-logos/index.js";
import SlimLogos from "./slim-logos/index.js";
import AllLogosLogos from "./all-logos-logos/index.js";
import TopLogos from "./top-logos/index.js";
import BottomLogos from "./bottom-logos/index.js";
import ImagesLogos from "./images-logos/index.js";
export default {
    // plop_marker:layouts_add_layout
    LogosAndParagraphLogos,
    SlimLogos,
    AllLogosLogos,
    TopLogos,
    BottomLogos,
    ImagesLogos
};
