function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import CarouselFieldsConfig from "segments/desktop/core-components/carousel/fields-config";
export default {
    type: FIELD_TYPES.NESTED,
    label: "Carousel",
    fields: _object_spread({
        disableCenterModeStyle: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Disable center mode style"
        },
        imageHeight: {
            type: FIELD_TYPES.TEXT,
            label: "Images height"
        },
        horizontalPadding: {
            type: FIELD_TYPES.TEXT,
            label: "Images horizontal padding"
        },
        transitionSpeed: {
            type: FIELD_TYPES.TEXT,
            label: "Slides transition speed (in miliseconds)"
        }
    }, CarouselFieldsConfig)
};
