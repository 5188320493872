import dynamic from "next/dynamic";
const PositionsCareersComponent = dynamic(()=>import("./positions-careers-component"));
import PositionsCareersConfig from "./positions-careers-config";
import PositionsCareersAdvancedConfig from "./positions-careers-advanced-config";
import PositionsCareersDynamicFetch from "./positions-careers-dynamics-fetch";
export default {
    PositionsCareersComponent,
    PositionsCareersConfig,
    PositionsCareersAdvancedConfig,
    PositionsCareersDynamicFetch
};
