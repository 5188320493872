function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import { trackBigBrainEventOnServer } from "/server/services/bigbrain-tracking-service/bigbrain-tracking-service";
import MondayBoardConfigProvider from "/server/services/data-service/providers/monday-board-config-provider";
import { getCachedData } from "/server/services/data-service/providers/base-data-provider";
import { MONDAY_CONFIG_INTERNAL_ERROR } from "/constants/bigbrain-event-types";
const getMondayConfig = function() {
    var _ref = _async_to_generator(function*({ query, boardId }) {
        let mondayConfig;
        try {
            const provider = new MondayBoardConfigProvider({
                query,
                boardId
            });
            mondayConfig = yield getCachedData(provider);
        } catch (exception) {
            trackBigBrainEventOnServer(MONDAY_CONFIG_INTERNAL_ERROR, {
                info1: exception.message,
                info2: query,
                info3: boardId
            });
        }
        return mondayConfig;
    });
    return function getMondayConfig(_) {
        return _ref.apply(this, arguments);
    };
}();
module.exports = {
    getMondayConfig
};
