function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import AssetFieldsConfig from "components/core/asset/fields-config";
import ButtonFieldsConfig from "segments/desktop/core-components/button/fields-config";
import { SIGNUP_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";
import SignupButtonFieldsConfig from "segments/desktop/core-components/button/signup-button/fields-config";
import { SM, MD } from "components/core/new-button/button-component";
const { image, video } = AssetFieldsConfig;
const _video_fields = video.fields, { playWhenVisible, loop } = _video_fields, videoOtherFields = _object_without_properties(_video_fields, [
    "playWhenVisible",
    "loop"
]);
const parallaxParagraphButtonConfig = _object_spread_props(_object_spread({}, ButtonFieldsConfig), {
    options: [
        {
            text: SIGNUP_BUTTON_TYPE,
            fieldsConfig: _object_spread_props(_object_spread({}, SignupButtonFieldsConfig), {
                invert: {
                    type: FIELD_TYPES.BOOLEAN,
                    label: "Invert  color"
                },
                buttonSize: {
                    type: FIELD_TYPES.COMBO,
                    label: "Size",
                    options: [
                        {
                            value: SM,
                            text: "Small"
                        },
                        {
                            value: MD,
                            text: "Medium"
                        }
                    ]
                }
            }),
            type: FIELD_TYPES.NESTED
        }
    ]
});
export default {
    type: FIELD_TYPES.NESTED,
    label: "Scroll Parallax",
    fields: {
        backgroundColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Background Color"
        },
        paragraphs: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Paragraphs",
            maxLimit: 3,
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: {
                    title: {
                        type: FIELD_TYPES.TEXT,
                        label: "Title",
                        translatable: true
                    },
                    description: {
                        type: FIELD_TYPES.TEXT,
                        label: "Description",
                        translatable: true
                    },
                    avatarSrc: {
                        type: FIELD_TYPES.GALLERY,
                        label: "Avatar",
                        defaultImagesKeys: []
                    },
                    avatarAlt: {
                        type: FIELD_TYPES.TEXT,
                        label: "Avatar alt text",
                        translatable: true
                    },
                    testimonial: {
                        type: FIELD_TYPES.TEXT,
                        label: "Testimonial",
                        translatable: true
                    },
                    name: {
                        type: FIELD_TYPES.TEXT,
                        label: "Name"
                    },
                    jobTitle: {
                        type: FIELD_TYPES.TEXT,
                        label: "Job title",
                        translatable: true
                    },
                    companyName: {
                        type: FIELD_TYPES.TEXT,
                        label: "Company name"
                    },
                    buttonConfig: parallaxParagraphButtonConfig
                }
            }
        },
        topAsset: _object_spread_props(_object_spread({}, image), {
            label: "Top asset"
        }),
        bottomAsset: _object_spread_props(_object_spread({}, video), {
            label: "Bottom asset",
            fields: videoOtherFields
        })
    }
};
