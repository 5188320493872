function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import AssetAdvancedConfig from "components/core/asset/advanced-field-config";
import FullParagraphAdvancedFieldsConfig from "segments/desktop/core-components/full-paragraph/advanced-fields-config";
import AdvancedThemeConfig from "segments/core-configs/advanced-theme-config";
const { topicBottomMargin, buttonTopMargin, withDynamicParagraphSubtitle, withDynamicParagraphTitle } = FullParagraphAdvancedFieldsConfig, restOfAdvancedParagraphConfig = _object_without_properties(FullParagraphAdvancedFieldsConfig, [
    "topicBottomMargin",
    "buttonTopMargin",
    "withDynamicParagraphSubtitle",
    "withDynamicParagraphTitle"
]);
export default {
    type: FIELD_TYPES.NESTED,
    label: "",
    fields: _object_spread_props(_object_spread({}, SharedAdvancedFieldsConfig, AdvancedThemeConfig), {
        advancedAssetProps: {
            type: FIELD_TYPES.NESTED,
            label: "Advanced asset config",
            fields: AssetAdvancedConfig
        },
        progressbarColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Progressbar color"
        },
        tabLabelHeight: {
            type: FIELD_TYPES.TEXT,
            label: "Tab label height (default 32px)"
        },
        transitionTime: {
            type: FIELD_TYPES.TEXT,
            label: "Transition time (ms) between slides - also set auto play"
        },
        tabContentGap: {
            type: FIELD_TYPES.TEXT,
            label: "Margin between testimonial and stats (default 40px)"
        },
        autoPlayWhenVisible: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Auto play when visible (make sure visibility sensor config is on)"
        },
        tabAdvancedConfig: {
            type: FIELD_TYPES.NESTED,
            label: "Tab advanced config",
            fields: {
                testimonialMaxWidth: {
                    type: FIELD_TYPES.TEXT,
                    label: "Testimonial max width"
                },
                advancedStatsConfig: {
                    type: FIELD_TYPES.NESTED,
                    fields: _object_spread_props(_object_spread({}, restOfAdvancedParagraphConfig), {
                        lineMargin: {
                            type: FIELD_TYPES.TEXT,
                            label: "Margin between line and stats"
                        }
                    }),
                    label: "Advanced stats config"
                }
            }
        }
    })
};
