import styled from "@emotion/styled";
export const StyledSideBySideWithIconsTextAndAssetMobileComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 32px 40px;
  .full-paragraph {
    .icons-wrapper {
      margin-bottom: 8px;
    }
  }
  .button-wrapper {
    margin-top: var(--margin-between-asset-and-button);
  }
  .two-link-buttons-button.swap-buttons-order {
    flex-direction: column-reverse;
  }
`;
