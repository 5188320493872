// plop_marker:layouts_import
import FullBackgroundHero from "./full-background-hero/index.js";
import SideBySideWithBackgroundHero from "./side-by-side-with-background-hero/index.js";
import SideBySideHero from "./side-by-side-hero/index.js";
export default {
    // plop_marker:layouts_add_layout
    FullBackgroundHero,
    SideBySideWithBackgroundHero,
    SideBySideHero
};
