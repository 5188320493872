function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import { SPACE_BLUE_THEME_ID } from "segments/segments-service";
import { buttonColors } from "segments/button-service";
import { BACKGROUND_COLOR_OPTIONS } from "segments/segments-service";
import TitleAdvancedFieldsConfig from "segments/desktop/core-components/title/advanced-field-config";
export default {
    type: FIELD_TYPES.NESTED,
    label: "Live Board",
    fields: {
        theme: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Theme",
            possibleColors: [
                ...BACKGROUND_COLOR_OPTIONS,
                SPACE_BLUE_THEME_ID
            ]
        },
        nestedBoardItems: {
            type: FIELD_TYPES.NESTED,
            label: "Embedded Board",
            fields: {
                boardBannerText: {
                    type: FIELD_TYPES.TEXT,
                    label: "Board Banner Text",
                    translatable: true
                },
                embeddedBoardTitle: {
                    type: FIELD_TYPES.TEXT,
                    label: "Embedded Board Title",
                    translatable: true
                },
                addSecondLiveBoardSignUpButton: {
                    type: FIELD_TYPES.BOOLEAN,
                    label: "Add second sign up button in the live board"
                }
            }
        },
        circleConfig: {
            type: FIELD_TYPES.NESTED,
            label: "Circle",
            fields: {
                circleTitle: {
                    type: FIELD_TYPES.TEXT,
                    label: " Title",
                    translatable: true
                },
                circleTitleColor: {
                    type: FIELD_TYPES.COLOR_PICKER,
                    label: "Title Color"
                },
                circleTitleSize: _object_spread({}, TitleAdvancedFieldsConfig.titleSize),
                circleText: {
                    type: FIELD_TYPES.TEXT,
                    label: "Circle Text",
                    translatable: true
                },
                circleTextColor: {
                    type: FIELD_TYPES.COLOR_PICKER,
                    label: "Circle Text Color"
                },
                circleColor: {
                    type: FIELD_TYPES.COLOR_PICKER,
                    label: "Circle Color"
                },
                buttonText: {
                    type: FIELD_TYPES.TEXT,
                    label: "Circle Button Text",
                    translatable: true
                },
                buttonColor: {
                    type: FIELD_TYPES.COLOR_PICKER,
                    label: "Circle Button Color",
                    possibleColors: buttonColors
                }
            }
        },
        backgroundImage: {
            type: FIELD_TYPES.GALLERY,
            label: "Background Image"
        },
        boardImage: {
            type: FIELD_TYPES.GALLERY,
            label: "Board Image"
        }
    }
};
