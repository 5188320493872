function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import PersonalTextualInfoConfig from "segments/desktop/core-components/person-textual-info/fields-config";
const { color } = PersonalTextualInfoConfig, selectedTextualInfoFields = _object_without_properties(PersonalTextualInfoConfig, [
    "color"
]);
export default _object_spread({
    decorationColor: {
        type: FIELD_TYPES.COLOR_PICKER,
        label: "Decoration Color"
    },
    title: {
        type: FIELD_TYPES.TEXT,
        label: "Title",
        textArea: true,
        translatable: true
    },
    showStars: {
        type: "boolean",
        label: "Show Stars"
    },
    starsCount: {
        type: FIELD_TYPES.COMBO,
        label: "How many stars",
        options: [
            "1",
            "2",
            "3",
            "4",
            "5"
        ]
    }
}, selectedTextualInfoFields);
