import { FIELD_TYPES } from "constants/template-field-type";
import { buttonThemes, MD, LG } from "./constants";
export default {
    poster: {
        type: FIELD_TYPES.GALLERY,
        defaultImagesKeys: [],
        label: "Select an image placeholder",
        labelDescription: "Image should keep youtube video aspect ratio (default 16:9)"
    },
    alt: {
        type: FIELD_TYPES.TEXT,
        label: "Description (alt)",
        translatable: true
    },
    videoId: {
        type: FIELD_TYPES.TEXT,
        label: "Youtube Video ID"
    },
    playButtonColor: {
        type: FIELD_TYPES.COLOR_PICKER,
        label: "Play Button Color",
        possibleColors: Object.keys(buttonThemes)
    },
    playButtonSize: {
        type: FIELD_TYPES.COMBO,
        label: "Play Button Size",
        options: [
            {
                value: MD,
                text: "Medium"
            },
            {
                value: LG,
                text: "Large"
            }
        ]
    },
    showShadow: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Show shadow"
    }
};
