function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import FlexibleCardsGridConfig from "segments/desktop/grid/layouts/flexible-cards-grid/flexible-cards-grid-config";
import TitleOnlyParagraphConfig from "segments/desktop/paragraph/layouts/title-only-paragraph/title-only-paragraph-config";
import TitleAdvancedConfig from "segments/desktop/core-components/title/advanced-field-config";
import { ISRAEL_LOCATION, GLOBAL_LOCATION } from "./press-consts";
const _FlexibleCardsGridConfig_fields = FlexibleCardsGridConfig.fields, { gridItems } = _FlexibleCardsGridConfig_fields, resConfig = _object_without_properties(_FlexibleCardsGridConfig_fields, [
    "gridItems"
]);
const _TitleOnlyParagraphConfig_fields = TitleOnlyParagraphConfig.fields, { theme, buttonConfig } = _TitleOnlyParagraphConfig_fields, resTitleConfig = _object_without_properties(_TitleOnlyParagraphConfig_fields, [
    "theme",
    "buttonConfig"
]);
const { titleSize } = TitleAdvancedConfig;
export default {
    type: FIELD_TYPES.NESTED,
    label: "Press Component",
    fields: _object_spread_props(_object_spread({
        location: {
            type: FIELD_TYPES.COMBO,
            label: "Location",
            options: [
                {
                    value: ISRAEL_LOCATION,
                    text: "Israel"
                },
                {
                    value: GLOBAL_LOCATION,
                    text: "Global"
                }
            ]
        }
    }, resConfig), {
        titleConfig: {
            type: FIELD_TYPES.NESTED,
            label: "Title Config",
            fields: _object_spread_props(_object_spread({}, resTitleConfig), {
                titleSize
            })
        }
    })
};
