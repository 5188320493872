function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import TitleConfig from "segments/desktop/core-components/title/fields-config";
import FullParagraphFieldConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import buttonConfig from "segments/desktop/core-components/button/signup-button/fields-config.js";
import { PRODUCT_OPTIONS } from "constants/products";
import paragraphAdvancedConfig from "segments/desktop/core-components/paragraph/advanced-fields-config";
import { HOSPITAL, APPS, ILLUSTRATIVE } from "./consts";
const { signupWithsolutionAppReferenceId, signupClusterId } = buttonConfig, restSignupButtonConfig = _object_without_properties(buttonConfig, [
    "signupWithsolutionAppReferenceId",
    "signupClusterId"
]);
const { textSize } = paragraphAdvancedConfig;
export default {
    type: FIELD_TYPES.NESTED,
    label: "Monday Products",
    fields: {
        version: {
            type: FIELD_TYPES.COMBO,
            label: "Version",
            options: [
                {
                    value: HOSPITAL,
                    text: "Hospital"
                },
                {
                    value: APPS,
                    text: "Apps"
                },
                {
                    value: ILLUSTRATIVE,
                    text: "Illustrative"
                }
            ]
        },
        isV2: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Is V2"
        },
        checkBoxInsteadOfModal: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Checkbox Instead Of Modal"
        },
        removeCheckbox: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Remove checkbox"
        },
        setCardImageAsBackground: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Set Card Image As Background"
        },
        idealForAndImage: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Ideal For And Image"
        },
        IdealForInBottomPart: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Add Ideal For In Card Bottom Part"
        },
        scaleWhenSelecting: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Scale When Selecting A Card"
        },
        removeSignupButton: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Remove Sign Up Button"
        },
        fullParagraph: {
            type: FIELD_TYPES.NESTED,
            label: "Full paragraph",
            fields: FullParagraphFieldConfig
        },
        subTitle: {
            type: FIELD_TYPES.NESTED,
            label: "Sub title",
            fields: TitleConfig
        },
        buttonConfig: {
            type: FIELD_TYPES.NESTED,
            label: "Signup button",
            fields: restSignupButtonConfig
        },
        distanceOfBottomPartFromTop: {
            type: FIELD_TYPES.TEXT,
            label: "Distance of bottom part from top when hovered"
        },
        cardWidth: {
            type: FIELD_TYPES.TEXT,
            label: "Card Width"
        },
        logoWidth: {
            type: FIELD_TYPES.TEXT,
            label: "Logo Width"
        },
        cardBackSidePaddingTop: {
            type: FIELD_TYPES.TEXT,
            label: "Card Back Side Padding Top"
        },
        departmentsHeight: {
            type: FIELD_TYPES.TEXT,
            label: "Departments Height"
        },
        departmentsTitle: {
            type: FIELD_TYPES.TEXT,
            label: "Departments title"
        },
        cancelFlip: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Cancel Flip"
        },
        products: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Products",
            maxLimit: 3,
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: {
                    productType: {
                        type: FIELD_TYPES.COMBO,
                        label: "Product type",
                        options: PRODUCT_OPTIONS
                    },
                    text: {
                        type: FIELD_TYPES.TEXT,
                        label: "Card Text",
                        translatable: true,
                        textArea: true
                    },
                    modalTitle: {
                        type: FIELD_TYPES.TEXT,
                        label: "Product modal title",
                        translatable: true,
                        textArea: true
                    },
                    modalSubTitle: {
                        type: FIELD_TYPES.TEXT,
                        label: "Product modal subtitle",
                        translatable: true,
                        textArea: true
                    },
                    textSize,
                    marginAboveCardText: {
                        type: FIELD_TYPES.TEXT,
                        label: "Margin Above Card Text"
                    },
                    marginUnderCardText: {
                        type: FIELD_TYPES.TEXT,
                        label: "Margin Under Card Text"
                    },
                    logoImage: {
                        type: FIELD_TYPES.GALLERY,
                        label: "Product Logo Image"
                    },
                    productImage: {
                        type: FIELD_TYPES.GALLERY,
                        label: "Product Image"
                    },
                    logoImageV2: {
                        type: FIELD_TYPES.GALLERY,
                        label: "Product Logo Image V2 (also in modal)"
                    },
                    productImageV2: {
                        type: FIELD_TYPES.GALLERY,
                        label: "Product Image V2"
                    },
                    cardBackImagev3: {
                        type: FIELD_TYPES.GALLERY,
                        label: "V3 Card Back Image"
                    },
                    productModalImage: {
                        type: FIELD_TYPES.GALLERY,
                        label: "V3 Product Modal Image"
                    },
                    backgroundColor: {
                        type: FIELD_TYPES.COLOR_PICKER,
                        label: "Color"
                    },
                    cardMainColor: {
                        type: FIELD_TYPES.COLOR_PICKER,
                        label: "Card Main Color"
                    },
                    cardButtonColor: {
                        type: FIELD_TYPES.COLOR_PICKER,
                        label: "Card Main CTA Button Color"
                    },
                    useCasesTagesTextColor: {
                        type: FIELD_TYPES.COLOR_PICKER,
                        label: "Use Cases Tags Text Color"
                    },
                    useCasesTagsBackgroundColor: {
                        type: FIELD_TYPES.COLOR_PICKER,
                        label: "Use Cases Tags Background Color"
                    },
                    bottomPartBackgroundColorV2: {
                        type: FIELD_TYPES.COLOR_PICKER,
                        label: "Bottom Part Background Color V2"
                    },
                    cardBackBackgroundColor: {
                        type: FIELD_TYPES.COLOR_PICKER,
                        label: "V3 Back color"
                    },
                    departments: {
                        type: FIELD_TYPES.MULTIPLE_NESTED,
                        label: "Departments",
                        maxLimit: 10,
                        nestedConfig: {
                            type: FIELD_TYPES.NESTED,
                            fields: {
                                text: {
                                    type: FIELD_TYPES.TEXT,
                                    label: "Text",
                                    translatable: true
                                }
                            }
                        }
                    },
                    useCases: {
                        type: FIELD_TYPES.MULTIPLE_NESTED,
                        label: "Use Cases",
                        maxLimit: 10,
                        nestedConfig: {
                            type: FIELD_TYPES.NESTED,
                            fields: {
                                text: {
                                    type: FIELD_TYPES.TEXT,
                                    label: "Text",
                                    translatable: true
                                }
                            }
                        }
                    },
                    preSelect: {
                        type: FIELD_TYPES.BOOLEAN,
                        label: "Pre Select Card"
                    }
                }
            }
        }
    }
};
