import Layouts from "./layouts/index.js";
const { // plop_marker:segment_index_import_layout
SimpoCustomerSuccess } = Layouts;
export default {
    // plop_marker:segment_index_add_layout
    SimpoCustomerSuccessComponent: {
        type: "SimpoCustomerSuccessComponent",
        title: "Simpo",
        imgSrc: "/static/img/segments/layouts/simpo-customer-success.png",
        config: SimpoCustomerSuccess.SimpoCustomerSuccessConfig,
        advancedConfig: SimpoCustomerSuccess.SimpoCustomerSuccessAdvancedConfig,
        component: SimpoCustomerSuccess.SimpoCustomerSuccessComponent
    }
};
