// plop_marker:layouts_import
import ParallaxVideoAsset from "./parallax-video-asset/index.js";
import FadeInAsset from "./fade-in-asset/index.js";
import VideoBackgroundAsset from "./video-background-asset/index.js";
import CarouselAsset from "./carousel-asset/index.js";
import VerticalTagsWithImagesAsset from "./vertical-tags-with-images-asset/index.js";
import ImagesCarouselWithTagsGridAsset from "./images-carousel-with-tags-grid-asset/index.js";
import EmbeddedYoutubeAsset from "./embedded-youtube-asset/index.js";
import YoutubeVideoAsset from "./youtube-video-asset/index.js";
import RegularAsset from "./regular-asset/index.js";
export default {
    // plop_marker:layouts_add_layout
    ParallaxVideoAsset,
    FadeInAsset,
    VideoBackgroundAsset,
    CarouselAsset,
    VerticalTagsWithImagesAsset,
    ImagesCarouselWithTagsGridAsset,
    EmbeddedYoutubeAsset,
    YoutubeVideoAsset,
    RegularAsset
};
