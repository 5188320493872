import Layouts from "./layouts/index.js";
const { // plop_marker:segment_index_import_layout
FullBackgroundHero, SideBySideWithBackgroundHero, SideBySideHero } = Layouts;
export default {
    // plop_marker:segment_index_add_layout
    FullBackgroundHeroComponent: {
        type: "FullBackgroundHeroComponent",
        title: "Full Background",
        imgSrc: "/static/img/segments/layouts/full-background-hero.png",
        config: FullBackgroundHero.FullBackgroundHeroConfig,
        component: FullBackgroundHero.FullBackgroundHeroComponent
    },
    SideBySideWithBackgroundHeroComponent: {
        type: "SideBySideWithBackgroundHeroComponent",
        title: "Side By Side With Background",
        imgSrc: "/static/img/segments/layouts/side-by-side-with-background-hero.png",
        config: SideBySideWithBackgroundHero.SideBySideWithBackgroundHeroConfig,
        component: SideBySideWithBackgroundHero.SideBySideWithBackgroundHeroComponent
    },
    SideBySideHeroComponent: {
        type: "SideBySideHeroComponent",
        title: "Side By Side",
        imgSrc: "/static/img/segments/layouts/side-by-side-hero.png",
        config: SideBySideHero.SideBySideHeroConfig,
        component: SideBySideHero.SideBySideHeroComponent
    }
};
