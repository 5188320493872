import { FIELD_TYPES } from "constants/template-field-type";
import BlogCardItemFieldsConfig from "segments/desktop/grid/layouts/blog-cards-grid/blog-card-item/fields-config";
import { REGULAR_BUTTON_TYPE, SECONDARY_BUTTON_TYPE, SIGNUP_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";
import RegularButtonFieldsConfig from "segments/desktop/core-components/button/regular-button/fields-config";
import SecondaryButtonFieldsConfig from "segments/desktop/core-components/button/secondary-button/fields-config";
import SignupButtonFieldsConfig from "segments/desktop/core-components/button/signup-button/fields-config";
export default {
    type: FIELD_TYPES.NESTED,
    label: "Blog Cards",
    fields: {
        gridItems: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Grid Items",
            maxLimit: 100,
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: BlogCardItemFieldsConfig
            }
        },
        buttonConfig: {
            type: FIELD_TYPES.CONDITIONAL_NESTED,
            label: "Button",
            conditionalKeyName: "buttonType",
            options: [
                {
                    text: REGULAR_BUTTON_TYPE,
                    fieldsConfig: RegularButtonFieldsConfig
                },
                {
                    text: SECONDARY_BUTTON_TYPE,
                    fieldsConfig: SecondaryButtonFieldsConfig
                },
                {
                    text: SIGNUP_BUTTON_TYPE,
                    fieldsConfig: SignupButtonFieldsConfig
                }
            ]
        }
    }
};
