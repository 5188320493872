import Layouts from "./layouts/index.js";
const { // plop_marker:segment_index_import_layout
LogosAndHeaderSecurity } = Layouts;
export default {
    // plop_marker:segment_index_add_layout
    LogosAndHeaderSecurityComponent: {
        type: "LogosAndHeaderSecurityComponent",
        title: "Logos And Header",
        imgSrc: "/static/img/segments/layouts/logos-and-header-security.png",
        config: LogosAndHeaderSecurity.LogosAndHeaderSecurityConfig,
        advancedConfig: LogosAndHeaderSecurity.LogosAndHeaderSecurityAdvancedConfig,
        component: LogosAndHeaderSecurity.LogosAndHeaderSecurityComponent
    }
};
