import { FIELD_TYPES } from "constants/template-field-type";
export default {
    logosScale: {
        type: FIELD_TYPES.TEXT,
        label: "Logos scale"
    },
    useSingleLine: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Single Line Layout"
    },
    paddingInlined: {
        type: FIELD_TYPES.TEXT,
        label: "Horizontal padding"
    },
    maxWidth: {
        type: FIELD_TYPES.TEXT,
        label: "Max width"
    }
};
