function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import AssetFieldsConfig from "components/core/asset/fields-config";
const { video } = AssetFieldsConfig;
const _video_fields = video.fields, { loop, playWhenVisible } = _video_fields, rest = _object_without_properties(_video_fields, [
    "loop",
    "playWhenVisible"
]);
export default {
    type: FIELD_TYPES.NESTED,
    label: "Masonry",
    fields: {
        assets: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Assets",
            maxLimit: 9,
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: {
                    asset: {
                        type: FIELD_TYPES.NESTED,
                        label: "Asset",
                        fields: _object_spread_props(_object_spread({}, AssetFieldsConfig), {
                            video: {
                                type: FIELD_TYPES.NESTED,
                                label: "Video",
                                fields: _object_spread_props(_object_spread({}, rest), {
                                    startAfter: {
                                        type: FIELD_TYPES.TEXT,
                                        label: "Start After"
                                    },
                                    poster: {
                                        type: FIELD_TYPES.GALLERY,
                                        label: "Select an poster"
                                    }
                                })
                            }
                        })
                    }
                }
            }
        }
    }
};
