// plop_marker:layouts_import
import ReviewCardsReviews from "./review-cards-reviews/index.js";
import ParagraphAndCarouselReviews from "./paragraph-and-carousel-reviews/index.js";
import RegularReviews from "./regular-reviews/index.js";
import WithTitleReviews from "./with-title-reviews/index.js";
export default {
    // plop_marker:layouts_add_layout
    ReviewCardsReviews,
    ParagraphAndCarouselReviews,
    RegularReviews,
    WithTitleReviews
};
