import { FIELD_TYPES } from "constants/template-field-type";
import AssetFieldsConfig from "components/core/asset/fields-config";
const { image } = AssetFieldsConfig;
export default {
    type: FIELD_TYPES.NESTED,
    label: "Icons",
    fields: {
        icons: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Icons",
            maxLimit: 3,
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: {
                    image,
                    title: {
                        type: FIELD_TYPES.TEXT,
                        label: "Title",
                        translatable: true
                    },
                    subtitle: {
                        type: FIELD_TYPES.TEXT,
                        label: "Subtitle",
                        translatable: true
                    }
                }
            }
        }
    }
};
