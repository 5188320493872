function _extends() {
    _extends = Object.assign || function(target) {
        for(var i = 1; i < arguments.length; i++){
            var source = arguments[i];
            for(var key in source){
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }
        return target;
    };
    return _extends.apply(this, arguments);
}
function _object_destructuring_empty(o) {
    if (o === null || o === void 0) throw new TypeError("Cannot destructure " + o);
    return o;
}
import { FIELD_TYPES } from "constants/template-field-type";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
const selectedFullParagraphFields = _extends({}, _object_destructuring_empty(FullParagraphFieldsConfig));
export default {
    type: FIELD_TYPES.NESTED,
    label: "Asymmetric",
    fields: {
        topColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Top Color"
        },
        fullParagraph: {
            type: FIELD_TYPES.NESTED,
            label: "Full Paragraph",
            fields: selectedFullParagraphFields
        }
    }
};
