import { FIELD_TYPES } from "constants/template-field-type";
import { leadSourceConfigList } from "client/services/hubspot-service/hubspot-constants";
export default {
    source: {
        type: FIELD_TYPES.COMBO,
        label: "Lead Source",
        options: leadSourceConfigList
    },
    withPrivacyNotice: {
        type: FIELD_TYPES.BOOLEAN,
        label: "With Privacy Notice"
    }
};
