import Layouts from "./layouts/index.js";
const { // plop_marker:segment_index_import_layout
SideBySideWithParagraphsParagraph, TwoParagraphsParagraph, UniverseParagraph, SideBySideTitleParagraph, RotatingTitleParagraph, FullWithBulletsParagraph, FullParagraph, TitleOnlyParagraph } = Layouts;
export default {
    // plop_marker:segment_index_add_layout
    SideBySideWithParagraphsParagraphComponent: {
        type: "SideBySideWithParagraphsParagraphComponent",
        title: "Side By Side With Paragraphs",
        imgSrc: "/static/img/segments/layouts/side-by-side-with-paragraphs-paragraph.png",
        config: SideBySideWithParagraphsParagraph.SideBySideWithParagraphsParagraphConfig,
        advancedConfig: SideBySideWithParagraphsParagraph.SideBySideWithParagraphsParagraphAdvancedConfig,
        component: SideBySideWithParagraphsParagraph.SideBySideWithParagraphsParagraphComponent
    },
    TwoParagraphsParagraphComponent: {
        type: "TwoParagraphsParagraphComponent",
        title: "Two Paragraphs",
        imgSrc: "/static/img/segments/layouts/two-paragraphs-paragraph.png",
        config: TwoParagraphsParagraph.TwoParagraphsParagraphConfig,
        advancedConfig: TwoParagraphsParagraph.TwoParagraphsParagraphAdvancedConfig,
        component: TwoParagraphsParagraph.TwoParagraphsParagraphComponent
    },
    UniverseParagraphComponent: {
        type: "UniverseParagraphComponent",
        title: "Universe mobile",
        imgSrc: "/static/img/segments/layouts/universe-paragraph.png",
        config: UniverseParagraph.UniverseParagraphConfig,
        advancedConfig: UniverseParagraph.UniverseParagraphAdvancedConfig,
        component: UniverseParagraph.UniverseParagraphComponent
    },
    SideBySideTitleParagraphComponent: {
        type: "SideBySideTitleParagraphComponent",
        title: "Side By Side Title",
        imgSrc: "/static/img/segments/layouts/side-by-side-title-paragraph.png",
        config: SideBySideTitleParagraph.SideBySideTitleParagraphConfig,
        advancedConfig: SideBySideTitleParagraph.SideBySideTitleParagraphAdvancedConfig,
        component: SideBySideTitleParagraph.SideBySideTitleParagraphComponent
    },
    RotatingTitleParagraphComponent: {
        type: "RotatingTitleParagraphComponent",
        title: "Rotating Title",
        imgSrc: "/static/img/segments/layouts/rotating-title-paragraph.png",
        config: RotatingTitleParagraph.RotatingTitleParagraphConfig,
        advancedConfig: RotatingTitleParagraph.RotatingTitleParagraphAdvancedConfig,
        component: RotatingTitleParagraph.RotatingTitleParagraphComponent
    },
    FullWithBulletsParagraphComponent: {
        type: "FullWithBulletsParagraphComponent",
        title: "Full With Bullets",
        imgSrc: "/static/img/segments/layouts/full-with-bullets-paragraph.png",
        config: FullWithBulletsParagraph.FullWithBulletsParagraphConfig,
        advancedConfig: FullWithBulletsParagraph.FullWithBulletsParagraphAdvancedConfig,
        component: FullWithBulletsParagraph.FullWithBulletsParagraphComponent
    },
    FullParagraphComponent: {
        type: "FullParagraphComponent",
        title: "Full",
        imgSrc: "/static/img/segments/layouts/full-paragraph.png",
        config: FullParagraph.FullParagraphConfig,
        advancedConfig: FullParagraph.FullParagraphAdvancedConfig,
        component: FullParagraph.FullParagraphComponent
    },
    TitleOnlyParagraphComponent: {
        type: "TitleOnlyParagraphComponent",
        title: "Title Only",
        imgSrc: "/static/img/segments/layouts/title-only-paragraph.png",
        config: TitleOnlyParagraph.TitleOnlyParagraphConfig,
        advancedConfig: TitleOnlyParagraph.TitleOnlyParagraphAdvancedConfig,
        component: TitleOnlyParagraph.TitleOnlyParagraphComponent
    }
};
