// plop_marker:segments_import
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import Templates from "./templates/index.js";
import Shape from "./shape/index.js";
import Pricing from "./pricing/index.js";
import CustomerSuccess from "./customer-success/index.js";
import Form from "./form/index.js";
import Blog from "./blog/index.js";
import Iframe from "./iframe/index.js";
import Static from "./static/index.js";
import Embedded from "./embedded/index.js";
import Banner from "./banner/index.js";
import HomepageFirstFold from "./homepage-first-fold/index.js";
import Integrations from "./integrations/index.js";
import Button from "./button/index.js";
import Enterprise from "./enterprise/index.js";
import Asset from "./asset/index.js";
import Security from "./security/index.js";
import Tabs from "./tabs/index.js";
import Header from "./header/index.js";
import Careers from "./careers/index.js";
import Hero from "./hero/index.js";
import Footer from "./footer/index.js";
import Grid from "./grid/index.js";
import Testimonials from "./testimonials/index.js";
import Logos from "./logos/index.js";
import Reviews from "./reviews/index.js";
import TextAndAsset from "./text-and-asset/index.js";
import Paragraph from "./paragraph/index.js";
export default _object_spread({}, Templates, Careers, Shape, Pricing, CustomerSuccess, Form, Blog, Iframe, Static, Embedded, Banner, HomepageFirstFold, Integrations, Button, Enterprise, Asset, Security, Tabs, Header, Hero, Footer, Grid, Testimonials, Logos, Reviews, TextAndAsset, Paragraph);
