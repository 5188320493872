function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import ContactSalesHubspotFormConfig from "segments/desktop/iframe/layouts/side-by-side-hubspot-form/components/fields-config";
import { CUSTOMER_KEY_NISSAN, CUSTOMER_KEY_OSCAR, CUSTOMER_KEY_TELEFONICA, CUSTOMER_KEY_THE_STUDENT_HOTEL, CUSTOMER_KEY_ZIPPO } from "constants/customer-quotes";
import LogosTitleFieldsConfig from "segments/desktop/logos/components/logos-title/fields-config";
import LogosFieldsConfig from "segments/desktop/core-components/logos/fields-config";
import sideBySideTextAndAssetFieldsConfig from "segments/desktop/core-components/side-by-side-text-and-asset/fields-config";
import TitleAdvancedConfig from "segments/desktop/core-components/title/advanced-field-config";
const { titleSize } = TitleAdvancedConfig;
const { swapTextAndAssetPosition, mobileSwapTextAndAssetPosition } = sideBySideTextAndAssetFieldsConfig;
export default {
    type: FIELD_TYPES.NESTED,
    label: "Hubspot Dynamic Form With Bullets Side By Side",
    fields: {
        swapTextAndAssetPosition,
        mobileSwapTextAndAssetPosition,
        form: {
            type: FIELD_TYPES.NESTED,
            label: "Hubspot Dynamic Form",
            fields: _object_spread({
                formId: {
                    type: FIELD_TYPES.TEXT,
                    label: "Form ID"
                },
                formWidth: {
                    type: FIELD_TYPES.TEXT,
                    label: "Form Width (specify units: px/%)"
                },
                urlToRedirectAfterSubmission: {
                    type: FIELD_TYPES.TEXT,
                    label: "URL to redirect after submission (In Webflow)"
                }
            }, ContactSalesHubspotFormConfig)
        },
        content: {
            type: FIELD_TYPES.NESTED,
            label: "Content",
            fields: {
                title: {
                    type: FIELD_TYPES.TEXT,
                    label: "Title",
                    translatable: true
                },
                titleSize,
                benefits: {
                    type: FIELD_TYPES.MULTIPLE_NESTED,
                    label: "Benefits",
                    maxLimit: 3,
                    nestedConfig: {
                        type: FIELD_TYPES.NESTED,
                        fields: {
                            icon: {
                                type: FIELD_TYPES.GALLERY,
                                label: "Icon"
                            },
                            title: {
                                type: FIELD_TYPES.TEXT,
                                label: "Title",
                                translatable: true
                            },
                            description: {
                                type: FIELD_TYPES.TEXT,
                                label: "Description",
                                translatable: true
                            }
                        }
                    }
                },
                hideSupportStatement: {
                    type: FIELD_TYPES.BOOLEAN,
                    label: "Hide support statement"
                },
                hideLogos: {
                    type: FIELD_TYPES.BOOLEAN,
                    label: "Hide logos"
                },
                customerQuoteKey: {
                    type: FIELD_TYPES.COMBO,
                    label: "Customer Quote",
                    options: [
                        {
                            value: CUSTOMER_KEY_OSCAR,
                            text: "Oscar - Work OS saves time and money"
                        },
                        {
                            value: CUSTOMER_KEY_THE_STUDENT_HOTEL,
                            text: "The Student Hotel - Work OS provides a trustful overview"
                        },
                        {
                            value: CUSTOMER_KEY_TELEFONICA,
                            text: "Telefonica - 30% more efficient in campaign delivering"
                        },
                        {
                            value: CUSTOMER_KEY_ZIPPO,
                            text: "Zippo - launch projects and expand to new markets in less time"
                        },
                        {
                            value: CUSTOMER_KEY_NISSAN,
                            text: "Nissan - been given confidence to provide management information"
                        }
                    ]
                },
                logosTitle: {
                    type: FIELD_TYPES.NESTED,
                    label: "Logos title",
                    fields: LogosTitleFieldsConfig
                },
                logos: {
                    type: FIELD_TYPES.NESTED,
                    label: "Logos",
                    fields: LogosFieldsConfig
                }
            }
        }
    }
};
