function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import AssetFieldsConfig from "components/core/asset/fields-config.js";
import fullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config.js";
import LogosTitleFieldsConfig from "segments/desktop/logos/components/logos-title/fields-config";
import LogosFieldsConfig from "segments/desktop/core-components/logos/fields-config";
import ThemeConfig from "segments/core-configs/theme-config";
export default {
    type: FIELD_TYPES.NESTED,
    label: "Video Background",
    fields: _object_spread_props(_object_spread({}, ThemeConfig), {
        isCentrilizeContent: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Centrilize Content"
        },
        fullParagraphConfig: {
            type: FIELD_TYPES.NESTED,
            label: "Full Paragrapph",
            fields: _object_spread({}, fullParagraphFieldsConfig)
        },
        assetConfig: {
            type: FIELD_TYPES.NESTED,
            label: "Asset",
            fields: _object_spread({}, AssetFieldsConfig)
        },
        addDarkOverlay: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Add Dark Overlay On Video"
        },
        hideBlackBars: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Hide Black Bars"
        },
        logosProps: {
            type: FIELD_TYPES.CONDITIONAL_NESTED,
            label: "Add logos",
            conditionalKeyName: "useLogos",
            options: [
                {
                    text: "Yes",
                    fieldsConfig: _object_spread_props(_object_spread({}, LogosTitleFieldsConfig), {
                        logos: {
                            type: FIELD_TYPES.NESTED,
                            label: "Logos",
                            fields: LogosFieldsConfig
                        }
                    })
                }
            ]
        },
        videoBorderRadius: {
            type: FIELD_TYPES.TEXT,
            label: "Video Border Radius"
        }
    })
};
