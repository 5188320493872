import { FIELD_TYPES } from "constants/template-field-type";
export default {
    cardFontSize: {
        type: FIELD_TYPES.TEXT,
        label: "Card Font Size"
    },
    reviewCardOverrideHeight: {
        type: FIELD_TYPES.TEXT,
        label: "Override Review Card Height"
    }
};
