function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import ThemeConfig from "segments/core-configs/theme-config";
import AssetFieldsConfig from "components/core/asset/fields-config";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import { RACKLEY_BLUE, SPRING_GREEN, ROUGE_RED } from "styles/color-consts";
const { textAlign, mobileTextAlign, buttonConfig: titleButtonConfig, titleColor, textColor, bodyColor, topic } = FullParagraphFieldsConfig, resOfParagraphConfig = _object_without_properties(FullParagraphFieldsConfig, [
    "textAlign",
    "mobileTextAlign",
    "buttonConfig",
    "titleColor",
    "textColor",
    "bodyColor",
    "topic"
]);
export default {
    type: FIELD_TYPES.NESTED,
    label: "Hoverable Cards",
    fields: _object_spread_props(_object_spread({}, ThemeConfig), {
        defaultHoveredCard: {
            type: FIELD_TYPES.TEXT,
            label: "Default hovered card"
        },
        defaultColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Card color",
            possibleColors: [
                ...ThemeConfig.theme.possibleColors,
                RACKLEY_BLUE
            ]
        },
        cards: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Cards",
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: {
                    hoveredColor: {
                        type: FIELD_TYPES.COLOR_PICKER,
                        label: "Hovered color",
                        possibleColors: [
                            ...ThemeConfig.theme.possibleColors,
                            SPRING_GREEN,
                            ROUGE_RED
                        ]
                    },
                    asset: {
                        type: FIELD_TYPES.NESTED,
                        label: "Asset",
                        fields: AssetFieldsConfig
                    },
                    hoveredAsset: {
                        type: FIELD_TYPES.NESTED,
                        label: "Hovered asset",
                        fields: AssetFieldsConfig
                    },
                    paragraph: {
                        type: FIELD_TYPES.NESTED,
                        label: "Paragraph",
                        fields: resOfParagraphConfig
                    }
                }
            }
        }
    })
};
