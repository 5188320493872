import Layouts from "./layouts/index.js";
const { // plop_marker:segment_index_import_layout
HubspotDynamicFormIframe, HubspotDynamicFormWithBulletsSideBySideIframe, EmbeddedBoardIframe, SideBySideHubspotForm, IframeDynamicWidthIframe } = Layouts;
export default {
    // plop_marker:segment_index_add_layout
    HubspotDynamicFormIframeComponent: {
        type: "HubspotDynamicFormIframeComponent",
        title: "Hubspot Dynamic Form",
        imgSrc: "/static/img/segments/layouts/hubspot-dynamic-form-iframe.png",
        config: HubspotDynamicFormIframe.HubspotDynamicFormIframeConfig,
        advancedConfig: HubspotDynamicFormIframe.HubspotDynamicFormIframeAdvancedConfig,
        component: HubspotDynamicFormIframe.HubspotDynamicFormIframeComponent
    },
    HubspotDynamicFormWithBulletsSideBySideIframeComponent: {
        type: "HubspotDynamicFormWithBulletsSideBySideIframeComponent",
        title: "Hubspot Dynamic Form With Bullets Side By Side",
        imgSrc: "/static/img/segments/layouts/hubspot-dynamic-form-with-bullets-side-by-side-iframe.png",
        config: HubspotDynamicFormWithBulletsSideBySideIframe.HubspotDynamicFormWithBulletsSideBySideIframeConfig,
        advancedConfig: HubspotDynamicFormWithBulletsSideBySideIframe.HubspotDynamicFormWithBulletsSideBySideIframeAdvancedConfig,
        component: HubspotDynamicFormWithBulletsSideBySideIframe.HubspotDynamicFormWithBulletsSideBySideIframeComponent
    },
    EmbeddedBoardIframeComponent: {
        type: "EmbeddedBoardIframeComponent",
        title: "Embedded Board",
        imgSrc: "/static/img/segments/layouts/embedded-board-iframe.png",
        config: EmbeddedBoardIframe.EmbeddedBoardIframeConfig,
        advancedConfig: EmbeddedBoardIframe.EmbeddedBoardIframeAdvancedConfig,
        component: EmbeddedBoardIframe.EmbeddedBoardIframeComponent
    },
    SideBySideHubspotFormComponent: {
        type: "SideBySideHubspotFormComponent",
        title: "Hubspot old form (Use only in case of custom form id)",
        imgSrc: "/static/img/segments/layouts/side-by-side-hubspot-form.png",
        config: SideBySideHubspotForm.SideBySideHubspotFormConfig,
        advancedConfig: SideBySideHubspotForm.SideBySideHubspotFormAdvancedConfig,
        component: SideBySideHubspotForm.SideBySideHubspotFormComponent
    },
    IframeDynamicWidthIframeComponent: {
        type: "IframeDynamicWidthIframeComponent",
        title: "Basic Iframe",
        imgSrc: "/static/img/segments/layouts/iframe-dynamic-width-iframe.png",
        config: IframeDynamicWidthIframe.IframeDynamicWidthIframeConfig,
        advancedConfig: IframeDynamicWidthIframe.IframeDynamicWidthIframeAdvancedConfig,
        component: IframeDynamicWidthIframe.IframeDynamicWidthIframeComponent
    }
};
