function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
const { trackBigBrainEventOnServer } = require("/server/services/bigbrain-tracking-service/bigbrain-tracking-service");
const { BaseDataProvider } = require("./base-data-provider");
const ProviderKeys = require("./providers-keys");
const { MONDAY_CONFIG_FETCH_ERROR } = require("/constants/bigbrain-event-types");
const REFRESH_INTERVAL_MINUTES = 15 * 60;
const MONDAY_API_URL = "https://api.monday.com/v2";
const MONDAY_API_TOKEN_NAME = "monday_api_token";
const getMondayServiceAPIKey = function() {
    var _ref = _async_to_generator(function*() {
        const { get } = require("../../../../secrets-service");
        return get(MONDAY_API_TOKEN_NAME);
    });
    return function getMondayServiceAPIKey() {
        return _ref.apply(this, arguments);
    };
}();
class MondayBoardConfigProvider extends BaseDataProvider {
    key() {
        const { boardId } = this.params();
        return `${ProviderKeys.MONDAY_BOARD_CONFIG}_board_${boardId}`;
    }
    refreshInterval() {
        return REFRESH_INTERVAL_MINUTES;
    }
    validate(value) {
        var _value_data_boards__items_page, _value_data_boards_, _value_data_boards, _value_data;
        const items = value === null || value === void 0 ? void 0 : (_value_data = value.data) === null || _value_data === void 0 ? void 0 : (_value_data_boards = _value_data.boards) === null || _value_data_boards === void 0 ? void 0 : (_value_data_boards_ = _value_data_boards[0]) === null || _value_data_boards_ === void 0 ? void 0 : (_value_data_boards__items_page = _value_data_boards_.items_page) === null || _value_data_boards__items_page === void 0 ? void 0 : _value_data_boards__items_page.items;
        return (items === null || items === void 0 ? void 0 : items.length) >= 20;
    }
    getData() {
        var _this = this;
        return _async_to_generator(function*() {
            const { query } = _this.params();
            try {
                const MONDAY_SERVICE_API_KEY = yield getMondayServiceAPIKey();
                const res = yield fetch(MONDAY_API_URL, {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: MONDAY_SERVICE_API_KEY,
                        "API-Version": "2024-04"
                    },
                    body: JSON.stringify({
                        query
                    })
                });
                if ((res === null || res === void 0 ? void 0 : res.status) === 200) {
                    const response = yield res.json();
                    return response;
                } else {
                    throw new Error(res.statusText);
                }
            } catch (e) {
                trackBigBrainEventOnServer(MONDAY_CONFIG_FETCH_ERROR, {
                    direct_object: e.message
                });
                console.error(e.message);
                throw e;
            }
        })();
    }
    constructor(params){
        super(params);
    }
}
export default MondayBoardConfigProvider;
