function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import AssetFieldsConfig from "components/core/asset/fields-config";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
const { topic, bodyColor, textColor } = FullParagraphFieldsConfig, fullParagraphConfig = _object_without_properties(FullParagraphFieldsConfig, [
    "topic",
    "bodyColor",
    "textColor"
]);
export default {
    type: FIELD_TYPES.NESTED,
    label: "Positions",
    fields: {
        emptyState: {
            type: FIELD_TYPES.NESTED,
            label: "Empty state",
            fields: {
                asset: {
                    type: FIELD_TYPES.NESTED,
                    label: "Asset",
                    fields: AssetFieldsConfig
                },
                fullParagraph: {
                    type: FIELD_TYPES.NESTED,
                    label: "Full paragraph",
                    fields: fullParagraphConfig
                }
            }
        }
    }
};
