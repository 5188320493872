import Layouts from "./layouts/index.js";
const { // plop_marker:segment_index_import_layout
PositionsCareers, SearchBarCareers } = Layouts;
export default {
    // plop_marker:segment_index_add_layout
    PositionsCareersComponent: {
        type: "PositionsCareersComponent",
        title: "Positions",
        imgSrc: "/static/img/segments/layouts/positions-careers.png",
        config: PositionsCareers.PositionsCareersConfig,
        advancedConfig: PositionsCareers.PositionsCareersAdvancedConfig,
        component: PositionsCareers.PositionsCareersComponent,
        dynamicsFetch: PositionsCareers.PositionsCareersDynamicFetch
    },
    SearchBarCareersComponent: {
        type: "SearchBarCareersComponent",
        title: "Search Bar Careers",
        imgSrc: "/static/img/segments/layouts/search-bar-careers.png",
        config: SearchBarCareers.SearchBarCareersConfig,
        advancedConfig: SearchBarCareers.SearchBarCareersAdvancedConfig,
        component: SearchBarCareers.SearchBarCareersComponent
    }
};
