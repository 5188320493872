function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import TitleConfig from "segments/desktop/core-components/title/fields-config";
import ParagraphConfig from "segments/desktop/core-components/paragraph/fields-config";
const { titleColor } = TitleConfig, restOfTitleConfig = _object_without_properties(TitleConfig, [
    "titleColor"
]);
const { bodyColor } = ParagraphConfig, restOfParagraphConfig = _object_without_properties(ParagraphConfig, [
    "bodyColor"
]);
export default {
    type: FIELD_TYPES.NESTED,
    label: "Products Cards",
    fields: {
        cardBackgroundColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Card background color"
        },
        productType: {
            type: FIELD_TYPES.PRODUCTS,
            label: "Product type"
        },
        cardTitle: {
            type: FIELD_TYPES.NESTED,
            label: "Card title",
            fields: _object_spread({
                productImage: {
                    type: FIELD_TYPES.GALLERY,
                    label: "Product logo image"
                }
            }, restOfTitleConfig, restOfParagraphConfig)
        },
        cardContent: {
            type: FIELD_TYPES.NESTED,
            label: "Card content",
            fields: _object_spread_props(_object_spread({}, restOfTitleConfig, restOfParagraphConfig), {
                textWidth: {
                    type: FIELD_TYPES.TEXT,
                    label: "Text max width"
                },
                titleWidth: {
                    type: FIELD_TYPES.TEXT,
                    label: "Title max width"
                },
                useCasesWidth: {
                    type: FIELD_TYPES.TEXT,
                    label: "Use case max width"
                },
                isUseCasesReadonly: {
                    type: FIELD_TYPES.BOOLEAN,
                    label: "Is use cases read only"
                },
                showUseCasesVertically: {
                    type: FIELD_TYPES.BOOLEAN,
                    label: "Show Use Cases Vertically"
                },
                cardPaddingTop: {
                    type: FIELD_TYPES.TEXT,
                    label: "Card Padding Top"
                },
                cardPaddingBottom: {
                    type: FIELD_TYPES.TEXT,
                    label: "Card Padding Bottom"
                },
                spaceUnderTitle: {
                    type: FIELD_TYPES.TEXT,
                    label: "Space Under Title"
                },
                useCases: {
                    type: FIELD_TYPES.MULTIPLE_NESTED,
                    label: "Use cases",
                    nestedConfig: {
                        type: FIELD_TYPES.NESTED,
                        fields: {
                            useCase: {
                                type: FIELD_TYPES.TEXT,
                                label: "Use cases"
                            },
                            overridePageCluster: {
                                type: FIELD_TYPES.CLUSTERS,
                                label: "Cluster key",
                                labelDescription: "Key which represents the selected tag cluster"
                            },
                            overridePageSubCluster: {
                                type: FIELD_TYPES.SUB_CLUSTERS,
                                label: "Use Case key",
                                labelDescription: "Key which represents the selected tag use case"
                            }
                        }
                    }
                },
                features: {
                    type: FIELD_TYPES.MULTIPLE_NESTED,
                    label: "Features",
                    nestedConfig: {
                        type: FIELD_TYPES.NESTED,
                        fields: {
                            feature: {
                                type: FIELD_TYPES.TEXT,
                                label: "Feature"
                            }
                        }
                    }
                }
            })
        },
        cardAsset: {
            type: FIELD_TYPES.GALLERY,
            label: "Card asset"
        }
    }
};
