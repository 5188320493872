import { FIELD_TYPES } from "constants/template-field-type";
import { leadSourceConfigList } from "client/services/hubspot-service/hubspot-constants";
import { CONTACT_SALES_HUBSPOT_FORM_ID, REQUEST_A_DEMO_HUBSPOT_FORM_ID, REPORT_FORM_ID, NURTURE_CONTACT_US_FORM_ID, EMAIL_ONLY_FORM_ID, EVENTS_HUBSPOT_FORM_ID } from "client/services/hubspot-service/contact-form-config";
const CONTACT_SALES_TEXT = `Contact Sales (${CONTACT_SALES_HUBSPOT_FORM_ID})`;
const REQUEST_A_DEMO_TEXT = `Request a Demo (${REQUEST_A_DEMO_HUBSPOT_FORM_ID})`;
const REPORT_FORM_TEXT = `Download form (${REPORT_FORM_ID})`;
const NURTURE_CONTUCT_US_TEXT = `Nurture - contact us form (${NURTURE_CONTACT_US_FORM_ID})`;
const EMAIL_ONLY_TEXT = `Email only - contact us form (${EMAIL_ONLY_FORM_ID})`;
const EVENTS_TEXT = `Events - contact us form (${EMAIL_ONLY_FORM_ID})`;
export default {
    formId: {
        type: FIELD_TYPES.COMBO,
        options: [
            {
                value: CONTACT_SALES_HUBSPOT_FORM_ID,
                text: CONTACT_SALES_TEXT
            },
            {
                value: REQUEST_A_DEMO_HUBSPOT_FORM_ID,
                text: REQUEST_A_DEMO_TEXT
            },
            {
                value: REPORT_FORM_ID,
                text: REPORT_FORM_TEXT
            },
            {
                value: NURTURE_CONTACT_US_FORM_ID,
                text: NURTURE_CONTUCT_US_TEXT
            },
            {
                value: EMAIL_ONLY_FORM_ID,
                text: EMAIL_ONLY_TEXT
            },
            {
                value: EVENTS_HUBSPOT_FORM_ID,
                text: EVENTS_TEXT
            }
        ],
        label: "Form ID"
    },
    source: {
        type: FIELD_TYPES.COMBO,
        label: "Lead Source",
        options: leadSourceConfigList
    },
    formTitle: {
        type: FIELD_TYPES.TEXT,
        label: "Form Title"
    },
    formSubTitle: {
        type: FIELD_TYPES.TEXT,
        label: "Form SubTitle"
    },
    urlToRedirectAfterSubmission: {
        type: FIELD_TYPES.TEXT,
        label: "URL to redirect after submission"
    },
    urlToRedirectAfterSubmissionForSmallBusinesses: {
        type: FIELD_TYPES.TEXT,
        label: "URL to redirect after submission for small businesses"
    },
    urlToRedirectAfterSubmissionEmailBookAMeeting: {
        type: FIELD_TYPES.TEXT,
        label: "URL to redirect after submission for email book a meeting test (in test - don't use)"
    },
    dontRenderPostFormSubmit: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Don't render post form submit"
    },
    splitForm: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Use split form (in test - don't use)"
    },
    timeoutForEnrichment: {
        type: FIELD_TYPES.TEXT,
        label: "Timeout for enrichment (in test - don't use)"
    },
    debounceTimeForEnrichment: {
        type: FIELD_TYPES.TEXT,
        label: "Debounce time for enrichment (in test - don't use)"
    },
    simulateBookAMeetingEmailTest: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Simulate book a meeting test"
    }
};
