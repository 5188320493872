// plop_marker:layouts_import
import ProductCardsSideBySideTextAndAsset from "./product-cards-side-by-side-text-and-asset/index.js";
import WhatsNewInnerPageSideBySideTextAndAsset from "./whats-new-inner-page-side-by-side-text-and-asset/index.js";
import YoutubeTextAndAsset from "./youtube-text-and-asset/index.js";
import SideBySideWithIconsTextAndAsset from "./side-by-side-with-icons-text-and-asset/index.js";
import BoardTabTextAndAsset from "./board-tab-text-and-asset/index.js";
import YoutubeTwoThirdsTextAndAsset from "./youtube-two-thirds-text-and-asset/index.js";
import AsymmetricSideBySideTextAndAsset from "./asymmetric-side-by-side-text-and-asset/index.js";
import TestimonialsTextAndAsset from "./testimonials-text-and-asset/index.js";
import SideBySideWithBulletsTextAndAsset from "./side-by-side-with-bullets-text-and-asset/index.js";
import TwoThirdsTextAndAsset from "./two-thirds-text-and-asset/index.js";
import FullBackgroundTextAndAsset from "./full-background-text-and-asset/index.js";
import SideBySideWithBackgroundTextAndAsset from "./side-by-side-with-background-text-and-asset/index.js";
import SideBySideTextAndAsset from "./side-by-side-text-and-asset/index.js";
export default {
    // plop_marker:layouts_add_layout
    ProductCardsSideBySideTextAndAsset,
    WhatsNewInnerPageSideBySideTextAndAsset,
    YoutubeTextAndAsset,
    SideBySideWithIconsTextAndAsset,
    BoardTabTextAndAsset,
    TwoThirdsTextAndAsset,
    FullBackgroundTextAndAsset,
    SideBySideWithBackgroundTextAndAsset,
    SideBySideTextAndAsset,
    YoutubeTwoThirdsTextAndAsset,
    AsymmetricSideBySideTextAndAsset,
    SideBySideWithBulletsTextAndAsset,
    TestimonialsTextAndAsset
};
