import { FIELD_TYPES } from "constants/template-field-type";
import LogosAdvancedFieldsConfig from "segments/desktop/core-components/logos/advanced-fields-config";
export default {
    type: FIELD_TYPES.NESTED,
    label: "Logos And Paragraph",
    fields: {
        logosAdvancedProps: {
            type: FIELD_TYPES.NESTED,
            label: "Logos Advanced Props",
            fields: LogosAdvancedFieldsConfig
        }
    }
};
