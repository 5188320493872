function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import TitleConfig from "segments/desktop/core-components/title/fields-config";
import ParagraphConfig from "segments/desktop/core-components/paragraph/fields-config";
import { WHITE_COLOR_NAME, BRAND_BLACK_TEXT_COLOR } from "styles/color-consts";
const { titleColor } = TitleConfig, restOfTitleConfig = _object_without_properties(TitleConfig, [
    "titleColor"
]);
const { bodyColor } = ParagraphConfig, restOfParagraphConfig = _object_without_properties(ParagraphConfig, [
    "bodyColor"
]);
export default {
    tabsTitle: {
        type: FIELD_TYPES.NESTED,
        label: "Title",
        fields: TitleConfig
    },
    tabs: {
        type: FIELD_TYPES.MULTIPLE_NESTED,
        label: "Tabs",
        nestedConfig: {
            type: FIELD_TYPES.NESTED,
            fields: {
                productType: {
                    type: FIELD_TYPES.PRODUCTS,
                    label: "Product type"
                },
                title: {
                    type: FIELD_TYPES.NESTED,
                    label: "Tab title",
                    fields: _object_spread_props(_object_spread({
                        productImage: {
                            type: FIELD_TYPES.GALLERY,
                            label: "Product logo image"
                        }
                    }, restOfTitleConfig, restOfParagraphConfig), {
                        useCases: {
                            type: FIELD_TYPES.MULTIPLE_NESTED,
                            label: "Use cases",
                            nestedConfig: {
                                type: FIELD_TYPES.NESTED,
                                fields: {
                                    useCase: {
                                        type: FIELD_TYPES.TEXT,
                                        label: "Use cases"
                                    }
                                }
                            }
                        },
                        useCasesColor: {
                            type: FIELD_TYPES.COLOR_PICKER,
                            label: "Use Cases Color"
                        },
                        useCasesTextColor: {
                            type: FIELD_TYPES.COLOR_PICKER,
                            label: "Use Cases Text Color",
                            possibleColors: [
                                WHITE_COLOR_NAME,
                                BRAND_BLACK_TEXT_COLOR
                            ]
                        }
                    })
                },
                content: {
                    type: FIELD_TYPES.NESTED,
                    label: "Product content",
                    fields: {
                        features: {
                            type: FIELD_TYPES.MULTIPLE_NESTED,
                            label: "Features",
                            nestedConfig: {
                                type: FIELD_TYPES.NESTED,
                                fields: {
                                    feature: {
                                        type: FIELD_TYPES.TEXT,
                                        label: "Feature"
                                    }
                                }
                            }
                        },
                        ctaText: {
                            type: FIELD_TYPES.TEXT,
                            label: "CTA text"
                        },
                        featursBulltesColor: {
                            type: FIELD_TYPES.COLOR_PICKER,
                            label: "Features Bulltes Color"
                        }
                    }
                }
            }
        }
    },
    tabTitleConfig: {
        type: FIELD_TYPES.NESTED,
        label: "Tabs titles",
        fields: {
            textWidth: {
                type: FIELD_TYPES.TEXT,
                label: "Text max width"
            },
            titleWidth: {
                type: FIELD_TYPES.TEXT,
                label: "Title max width"
            },
            spaceFromParagraph: {
                type: FIELD_TYPES.TEXT,
                label: "Margin between title and text"
            }
        }
    },
    isV2: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Is V2"
    },
    largerTags: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Larger Tags"
    }
};
