function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import { InternalServerError } from "server/extensions/error-extensions";
import { trackBigBrainEventOnServer } from "server/services/bigbrain-tracking-service/bigbrain-tracking-service";
import { CAREER_POSITIONS_RETRIEVE_FROM_COMEET } from "constants/bigbrain-event-types";
const POSITION_TYPE_CATEGORY_NAME = "Publish Position";
const POSITION_TYPE_INTERNAL_VALUE = "Internal Career Site";
const POSITION_TYPE_INTERNAL = "internal";
const POSITION_TYPE_INTERNAL_AND_EXTERNAL_VALUE = "Both";
const COMEET_COMPANY_UID = "41.00B"; // Used also in client side - fix
const COMEET_END_POINT = `https://www.comeet.co/careers-api/2.0/company/${COMEET_COMPANY_UID}/positions`;
const getDataFromComeet = function() {
    var _ref = _async_to_generator(function*(positionId = null) {
        const { get } = require("../../../../../secrets-service");
        const { api_token: COMEET_TOKEN } = yield get("comeet");
        const COMEET_PARAMS = `?token=${COMEET_TOKEN}&details=true`;
        const url = positionId ? `${COMEET_END_POINT}/${positionId}${COMEET_PARAMS}` : `${COMEET_END_POINT}${COMEET_PARAMS}`;
        const options = {
            insecureHTTPParser: true
        };
        yield trackBigBrainEventOnServer(CAREER_POSITIONS_RETRIEVE_FROM_COMEET, {
            kind: positionId ? "single_position" : "all_positions",
            info1: positionId
        });
        const response = yield fetch(url, options);
        return yield response.json();
    });
    return function getDataFromComeet() {
        return _ref.apply(this, arguments);
    };
}();
const getPublishCategoryValue = (position)=>{
    const publishCategory = position === null || position === void 0 ? void 0 : position.categories.find((category)=>category.name === POSITION_TYPE_CATEGORY_NAME);
    return publishCategory && publishCategory.value;
};
const isPositionRelevantToType = (position, positionsType)=>{
    const publishCategoryValue = getPublishCategoryValue(position);
    return publishedPositionFitsType(publishCategoryValue, positionsType);
};
const publishedPositionFitsType = (publishCategoryValue, requestedPositionsType)=>{
    if (requestedPositionsType === POSITION_TYPE_INTERNAL && publishCategoryValue) {
        return publishCategoryValue === POSITION_TYPE_INTERNAL_AND_EXTERNAL_VALUE || publishCategoryValue === POSITION_TYPE_INTERNAL_VALUE;
    }
    // else - external. filter out only internal
    return !publishCategoryValue || publishCategoryValue !== POSITION_TYPE_INTERNAL_VALUE;
};
const filterByPositionType = (allPositionsData, positionsType)=>{
    return allPositionsData.filter((position)=>isPositionRelevantToType(position, positionsType));
};
const getPositions = function() {
    var _ref = _async_to_generator(function*(query) {
        try {
            const { positionsType } = query;
            const allPositionsData = yield getDataFromComeet();
            if (!allPositionsData || allPositionsData.ERROR) {
                throw new InternalServerError("error fetching positions data");
            }
            return filterByPositionType(allPositionsData, positionsType);
        } catch (e) {
            return [];
        }
    });
    return function getPositions(query) {
        return _ref.apply(this, arguments);
    };
}();
export default [
    {
        propsPropertyName: "positions",
        shouldFetch: ()=>false,
        fetch: function() {
            var _ref = _async_to_generator(function*({ query }) {
                return yield getPositions(query);
            });
            return function(_) {
                return _ref.apply(this, arguments);
            };
        }()
    }
];
