function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import { getMondaySolutionById } from "server/services/solutions/solutions-api-service";
import { getSolutionById } from "services/templates-generator/api-service";
export default [
    {
        propsPropertyName: "solution",
        shouldFetch: ({ props, previousProps, pageConfig, previousPageConfig })=>{
            return props.solutionId && (props.solutionId !== previousProps.solutionId || pageConfig.localeId !== previousPageConfig.localeId);
        },
        fetch: function() {
            var _ref = _async_to_generator(function*({ props, pageConfig, isClientFetch }) {
                const { solutionId } = props;
                const { localeId, path } = pageConfig;
                let solution;
                if (isClientFetch) {
                    const res = yield getSolutionById({
                        solutionId,
                        localeId
                    });
                    solution = res.solution;
                } else {
                    solution = yield getMondaySolutionById({
                        solutionId,
                        localeId
                    });
                }
                if (!solution) {
                    throw new Error(`solution (id: ${solutionId}) can't be found in page ${path}`);
                }
                return solution;
            });
            return function(_) {
                return _ref.apply(this, arguments);
            };
        }()
    }
];
