function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import ThemeConfig from "segments/core-configs/theme-config";
import AssetConfig from "components/core/asset/fields-config";
import AssetAdvancedConfig from "components/core/asset/advanced-field-config";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import FullParagraphAdvancedConfig from "segments/desktop/core-components/full-paragraph/advanced-fields-config";
export default {
    type: FIELD_TYPES.NESTED,
    label: "Platform Interactive Experience",
    fields: _object_spread_props(_object_spread({}, ThemeConfig), {
        backgroundImage: {
            type: FIELD_TYPES.GALLERY,
            label: "Background Image"
        },
        backgroundSize: {
            type: FIELD_TYPES.TEXT,
            label: "Background Size"
        },
        glowImage: {
            type: FIELD_TYPES.NESTED,
            label: "Glow Image",
            fields: {
                src: {
                    type: FIELD_TYPES.GALLERY,
                    label: "Image"
                },
                width: {
                    type: FIELD_TYPES.TEXT,
                    label: "Width"
                },
                positionTop: {
                    type: FIELD_TYPES.TEXT,
                    label: "Position Top"
                },
                positionLeft: {
                    type: FIELD_TYPES.TEXT,
                    label: "Position Left"
                }
            }
        },
        interactions: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Interactions",
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: {
                    label: {
                        type: FIELD_TYPES.TEXT,
                        label: "Label"
                    },
                    icon: {
                        type: FIELD_TYPES.GALLERY,
                        label: "Icon"
                    },
                    image: {
                        type: FIELD_TYPES.NESTED,
                        label: "Image",
                        fields: {
                            src: {
                                type: FIELD_TYPES.GALLERY,
                                label: "Image"
                            },
                            width: {
                                type: FIELD_TYPES.TEXT,
                                label: "Width"
                            }
                        }
                    },
                    actionAsset: {
                        type: FIELD_TYPES.NESTED,
                        label: "Action asset",
                        fields: _object_spread({}, AssetConfig, AssetAdvancedConfig)
                    },
                    position: {
                        type: FIELD_TYPES.NESTED,
                        label: "Position",
                        fields: {
                            top: {
                                type: FIELD_TYPES.TEXT,
                                label: "Top"
                            },
                            left: {
                                type: FIELD_TYPES.TEXT,
                                label: "Left"
                            }
                        }
                    },
                    secondaryPosition: {
                        type: FIELD_TYPES.NESTED,
                        label: "Secondary Position (optional)",
                        fields: {
                            top: {
                                type: FIELD_TYPES.TEXT,
                                label: "Top"
                            },
                            left: {
                                type: FIELD_TYPES.TEXT,
                                label: "Left"
                            }
                        }
                    },
                    content: {
                        type: FIELD_TYPES.NESTED,
                        label: "Content",
                        fields: _object_spread({}, FullParagraphFieldsConfig, FullParagraphAdvancedConfig)
                    }
                }
            }
        }
    })
};
