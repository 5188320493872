import { FIELD_TYPES } from "constants/template-field-type";
export default {
    tagText: {
        type: FIELD_TYPES.TEXT,
        label: "Text",
        translatable: true
    },
    tagIconsrc: {
        type: FIELD_TYPES.GALLERY,
        label: "Select an Image"
    },
    href: {
        type: FIELD_TYPES.TEXT,
        label: "Href"
    },
    openInNewTab: {
        type: "boolean",
        label: "Should Open In New Tab"
    }
};
