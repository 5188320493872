import { FIELD_TYPES } from "constants/template-field-type";
export default {
    type: FIELD_TYPES.NESTED,
    label: "",
    fields: {
        stroke: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Stroke Color"
        },
        strokeWidth: {
            type: FIELD_TYPES.TEXT,
            label: "Stroke Width"
        },
        opacity: {
            type: FIELD_TYPES.TEXT,
            label: "Opacity"
        },
        shadowColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Shadow Color"
        },
        shadowBlur: {
            type: FIELD_TYPES.TEXT,
            label: "Shadow Blur"
        },
        shadowOffsetX: {
            type: FIELD_TYPES.TEXT,
            label: "Shadow Offset X"
        },
        shadowOffsetY: {
            type: FIELD_TYPES.TEXT,
            label: "Shadow Offset Y"
        },
        zIndex: {
            type: FIELD_TYPES.TEXT,
            label: "Z Index"
        },
        relativeTo: {
            type: FIELD_TYPES.TEXT,
            label: "Relative To (Segment ID)"
        }
    }
};
