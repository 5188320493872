function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import SideBySideTextAndAssetComponent from "segments/desktop/text-and-asset/layouts/side-by-side-text-and-asset/side-by-side-text-and-asset-component";
import iconsConfig from "segments/desktop/core-components/icons/default-props";
import { TWO_LINK_BUTTONS_TYPE } from "segments/desktop/core-components/button/button-consts";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import { LG } from "constants/sizes";
export default _object_spread_props(_object_spread({
    iconsConfig
}, SideBySideTextAndAssetComponent.defaultProps), {
    asset: {
        image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/customer-support/support_globe.png"
        }
    },
    fullParagraph: {
        topic: {},
        title: "Supporting <light>your growth<br>every step of the way</light>",
        body: "Our support superheroes are a click away to help you get the most out of monday.com, so you can focus on working without limits.",
        buttonConfig: {
            buttonType: TWO_LINK_BUTTONS_TYPE,
            swapButtonsOrder: true,
            regularButtonConfig: {
                color: WORK_OS_IRIS_COLOR_NAME,
                buttonText: "Get in touch",
                url: "https://monday.com/helpcenter/contact-support"
            },
            secondaryButtonConfig: {
                color: WORK_OS_IRIS_COLOR_NAME,
                buttonText: "Visit our support center",
                url: "https://support.monday.com/hc/en-us"
            }
        }
    },
    titleSize: LG,
    leftSizeWidth: "60%",
    rightSizeWidth: "40%",
    mobileMarginBetweenAssetAndButton: "48px"
});
