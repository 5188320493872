import { FIELD_TYPES } from "constants/template-field-type";
import AssetFieldsConfig from "components/core/asset/fields-config";
export default {
    type: FIELD_TYPES.MULTIPLE_NESTED,
    label: "Use cases",
    nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
            label: {
                type: FIELD_TYPES.TEXT,
                label: "Case label"
            },
            iconAsset: {
                type: FIELD_TYPES.GALLERY,
                label: "Case icon",
                defaultImagesKeys: []
            },
            asset: {
                type: FIELD_TYPES.NESTED,
                label: "Case asset",
                fields: AssetFieldsConfig
            }
        }
    }
};
