function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
const URL_BASE = process.env.MONDAY_BASE_PATH;
const LOCAL_URL_BASE = process.env.LOCAL_BASE_PATH;
const DEFAULT_FETCH_OPTIONS = {
    credentials: "same-origin",
    headers: {
        "Content-Type": "application/json"
    }
};
const baseFetch = (url, options)=>{
    return fetch(url, _object_spread({}, DEFAULT_FETCH_OPTIONS, options));
};
export const getPage = (path, options)=>{
    const isServer = !process.browser;
    const baseUrl = isServer ? LOCAL_URL_BASE : URL_BASE;
    return baseFetch(`${baseUrl}/get-page-config?path=${path}`);
};
export const getPageExists = (path, options)=>{
    return baseFetch(`${URL_BASE}/dynamic/templates-generator/generated-page-exists?path=${path}`);
};
export const removePage = (path)=>{
    return baseFetch(`${URL_BASE}/dynamic/templates-generator/remove-page?path=${path}`, {
        method: "POST"
    });
};
export const publishPage = (pageConfig)=>{
    return baseFetch(`${URL_BASE}/dynamic/templates-generator/publish`, {
        method: "POST",
        body: JSON.stringify({
            pageConfig
        })
    });
};
export const restorePage = (path, historyIndex)=>{
    return baseFetch(`${URL_BASE}/dynamic/templates-generator/restore-page`, {
        method: "POST",
        body: JSON.stringify({
            path,
            historyIndex
        })
    });
};
export const getOrCreatePageModel = (path)=>{
    return baseFetch(`${URL_BASE}/dynamic/templates-generator/get-or-create-page`, {
        method: "POST",
        body: JSON.stringify({
            path
        })
    });
};
export const migrateExistingTranslations = (path)=>{
    return baseFetch(`${URL_BASE}/dynamic/templates-generator/migrate-existing-translations`, {
        method: "POST",
        body: JSON.stringify({
            path
        })
    });
};
export const fetchTranslatedPages = (filter)=>{
    return baseFetch(`${URL_BASE}/dynamic/templates-generator/get-translated-pages?filter=${filter}`, {
        method: "GET"
    });
};
export const createTranslationJobs = (path, languages, translationStrategy)=>{
    return baseFetch(`${URL_BASE}/dynamic/templates-generator/create-translation-jobs`, {
        method: "POST",
        body: JSON.stringify({
            path,
            languages,
            translationStrategy
        })
    });
};
export const refreshTranslationJobsStatus = function() {
    var _ref = _async_to_generator(function*() {
        return baseFetch(`${URL_BASE}/dynamic/templates-generator/refresh-translation-jobs-status`, {
            method: "POST"
        });
    });
    return function refreshTranslationJobsStatus() {
        return _ref.apply(this, arguments);
    };
}();
export const fetchDraftConfig = (translationJobId)=>{
    return baseFetch(`${URL_BASE}/dynamic/templates-generator/get-translation-job/${translationJobId}/draft-config`, {
        method: "GET"
    });
};
export const createAbTest = (abTestData)=>{
    return baseFetch(`${URL_BASE}/dynamic/templates-generator/create-ab-test`, {
        method: "POST",
        body: JSON.stringify({
            abTestData
        })
    });
};
export const fetchAbTestsData = ()=>{
    return baseFetch(`${URL_BASE}/dynamic/templates-generator/fetch-ab-tests-data`, {
        method: "GET"
    });
};
export const startAbTest = (abTestId)=>{
    return baseFetch(`${URL_BASE}/dynamic/templates-generator/start-ab-test`, {
        method: "POST",
        body: JSON.stringify({
            abTestId
        })
    });
};
export const stopAbTest = (abTestId)=>{
    return baseFetch(`${URL_BASE}/dynamic/templates-generator/stop-ab-test`, {
        method: "POST",
        body: JSON.stringify({
            abTestId
        })
    });
};
export const getClusters = ()=>{
    return baseFetch(`${URL_BASE}/dynamic/templates-generator/get-clusters`, {
        method: "GET"
    });
};
export const getSolutions = function() {
    var _ref = _async_to_generator(function*() {
        const res = yield baseFetch(`${URL_BASE}/dynamic/templates-generator/get-solutions`, {
            method: "GET"
        });
        const solutions = yield res.json();
        return solutions;
    });
    return function getSolutions() {
        return _ref.apply(this, arguments);
    };
}();
export const getSolutionById = function() {
    var _ref = _async_to_generator(function*({ solutionId, localeId }) {
        const res = yield baseFetch(`${URL_BASE}/dynamic/templates-generator/get-solution?localeId=${localeId}&solutionId=${solutionId}`, {
            method: "GET"
        });
        const solution = yield res.json();
        return solution;
    });
    return function getSolutionById(_) {
        return _ref.apply(this, arguments);
    };
}();
