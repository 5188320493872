function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import { getMondaySolutionById } from "server/services/solutions/solutions-api-service";
import { getSolutionById } from "services/templates-generator/api-service";
const hasSameIds = (solutionsInfo, prevSolutionsInfo)=>{
    return !solutionsInfo.find(({ solutionId })=>{
        return !prevSolutionsInfo.find(({ solutionId: prevSolutionId })=>{
            return solutionId === prevSolutionId;
        });
    });
};
export default [
    {
        propsPropertyName: "solutions",
        shouldFetch: ({ props, previousProps, pageConfig, previousPageConfig })=>{
            var _props_solutionsInfo;
            let res = false;
            // Has ids
            if (((_props_solutionsInfo = props.solutionsInfo) === null || _props_solutionsInfo === void 0 ? void 0 : _props_solutionsInfo.length) > 0) {
                var _previousProps_solutionsInfo;
                // Add/Remove ids
                if (props.solutionsInfo.length !== ((_previousProps_solutionsInfo = previousProps.solutionsInfo) === null || _previousProps_solutionsInfo === void 0 ? void 0 : _previousProps_solutionsInfo.length)) {
                    res = true;
                // Id has changed
                } else if (!hasSameIds(props.solutionsInfo, previousProps.solutionsInfo)) {
                    res = true;
                // Locale has changed
                } else if (pageConfig.localeId !== previousPageConfig.localeId) {
                    res = true;
                }
            }
            return res;
        },
        fetch: function() {
            var _ref = _async_to_generator(function*({ props, pageConfig, isClientFetch }) {
                const { solutionsInfo } = props;
                const { localeId, path } = pageConfig;
                const solutionRequests = solutionsInfo.map(function() {
                    var _ref = _async_to_generator(function*(info) {
                        const { solutionId } = info;
                        if (solutionId) {
                            let solution;
                            if (isClientFetch) {
                                const res = yield getSolutionById({
                                    solutionId,
                                    localeId
                                });
                                solution = res.solution;
                            } else {
                                solution = yield getMondaySolutionById({
                                    solutionId,
                                    localeId
                                });
                            }
                            if (!solution) {
                                throw new Error(`solution (id: ${solutionId}) can't be found in page ${path}`);
                            }
                            return solution;
                        }
                    });
                    return function(info) {
                        return _ref.apply(this, arguments);
                    };
                }());
                const solutions = yield Promise.all(solutionRequests);
                return solutions;
            });
            return function(_) {
                return _ref.apply(this, arguments);
            };
        }()
    }
];
