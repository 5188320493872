import { FIELD_TYPES } from "constants/template-field-type";
export default {
    color: {
        type: FIELD_TYPES.COLOR_PICKER,
        label: "Color"
    },
    text: {
        type: FIELD_TYPES.TEXT,
        label: "Text",
        translatable: true
    }
};
