export { HrIcon } from "./hr-icon";
export { ItIcon } from "./it-icon";
export { CrmIcon } from "./crm-icon";
export { ProductIcon } from "./product-icon";
export { OperationsIcon } from "./operations-icon";
export { MarketingIcon } from "./marketing-icon";
export { DesignIcon } from "./design-icon";
export { TasksIcon } from "./tasks-icon";
export { ProjectsIcon } from "./projects-icon";
export { SoftwareIcon } from "./software-icon";
