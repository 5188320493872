import Layouts from "./layouts/index.js";
const { // plop_marker:segment_index_import_layout
SideBySideParagraphAndTagsHomepageFirstFold, PlatformInteractiveExperienceHomepageFirstFold, TagsByProductHomepageFirstFold, ProductsByUseCasesHomepageFirstFold, MondayProductsHomepageFirstFold, FullParagraphAndTagsHomepageFirstFold, LiveBoardHomepageFirstFold, SignUpWithProductsCardsHomepageFirstFold, WelcomeBackHomepageFirstFold, MondayUniverseHomepageFirstFold, ScrollParallaxHomepageFirstFold, GlobeWithTopCtaHomepageFirstFold, GlobeHomepageFirstFold, AnimatingBoardHomepageFirstFold } = Layouts;
export default {
    // plop_marker:segment_index_add_layout
    SideBySideParagraphAndTagsHomepageFirstFoldComponent: {
        type: "SideBySideParagraphAndTagsHomepageFirstFoldComponent",
        title: "Side By Side Paragraph And Tags",
        imgSrc: "/static/img/segments/layouts/side-by-side-paragraph-and-tags-homepage-first-fold.png",
        config: SideBySideParagraphAndTagsHomepageFirstFold.SideBySideParagraphAndTagsHomepageFirstFoldConfig,
        advancedConfig: SideBySideParagraphAndTagsHomepageFirstFold.SideBySideParagraphAndTagsHomepageFirstFoldAdvancedConfig,
        component: SideBySideParagraphAndTagsHomepageFirstFold.SideBySideParagraphAndTagsHomepageFirstFoldComponent
    },
    PlatformInteractiveExperienceHomepageFirstFoldComponent: {
        type: "PlatformInteractiveExperienceHomepageFirstFoldComponent",
        title: "Platform Interactive Experience",
        imgSrc: "/static/img/segments/layouts/platform-interactive-experience-homepage-first-fold.png",
        config: PlatformInteractiveExperienceHomepageFirstFold.PlatformInteractiveExperienceHomepageFirstFoldConfig,
        advancedConfig: PlatformInteractiveExperienceHomepageFirstFold.PlatformInteractiveExperienceHomepageFirstFoldAdvancedConfig,
        component: PlatformInteractiveExperienceHomepageFirstFold.PlatformInteractiveExperienceHomepageFirstFoldComponent
    },
    TagsByProductHomepageFirstFoldComponent: {
        type: "TagsByProductHomepageFirstFoldComponent",
        title: "Tags By Product",
        imgSrc: "/static/img/segments/layouts/tags-by-product-homepage-first-fold.png",
        config: TagsByProductHomepageFirstFold.TagsByProductHomepageFirstFoldConfig,
        advancedConfig: TagsByProductHomepageFirstFold.TagsByProductHomepageFirstFoldAdvancedConfig,
        component: TagsByProductHomepageFirstFold.TagsByProductHomepageFirstFoldComponent
    },
    ProductsByUseCasesHomepageFirstFoldComponent: {
        type: "ProductsByUseCasesHomepageFirstFoldComponent",
        title: "Products By Use Cases",
        imgSrc: "/static/img/segments/layouts/products-by-use-cases-homepage-first-fold.png",
        config: ProductsByUseCasesHomepageFirstFold.ProductsByUseCasesHomepageFirstFoldConfig,
        advancedConfig: ProductsByUseCasesHomepageFirstFold.ProductsByUseCasesHomepageFirstFoldAdvancedConfig,
        component: ProductsByUseCasesHomepageFirstFold.ProductsByUseCasesHomepageFirstFoldComponent
    },
    MondayProductsHomepageFirstFoldComponent: {
        type: "MondayProductsHomepageFirstFoldComponent",
        title: "Monday Products",
        imgSrc: "/static/img/segments/layouts/monday-products-homepage-first-fold.png",
        config: MondayProductsHomepageFirstFold.MondayProductsHomepageFirstFoldConfig,
        advancedConfig: MondayProductsHomepageFirstFold.MondayProductsHomepageFirstFoldAdvancedConfig,
        component: MondayProductsHomepageFirstFold.MondayProductsHomepageFirstFoldComponent
    },
    FullParagraphAndTagsHomepageFirstFoldComponent: {
        type: "FullParagraphAndTagsHomepageFirstFoldComponent",
        title: "Full Paragraph And Tags",
        imgSrc: "/static/img/segments/layouts/full-paragraph-and-tags-homepage-first-fold.png",
        config: FullParagraphAndTagsHomepageFirstFold.FullParagraphAndTagsHomepageFirstFoldConfig,
        advancedConfig: FullParagraphAndTagsHomepageFirstFold.FullParagraphAndTagsHomepageFirstFoldAdvancedConfig,
        component: FullParagraphAndTagsHomepageFirstFold.FullParagraphAndTagsHomepageFirstFoldComponent
    },
    LiveBoardHomepageFirstFoldComponent: {
        type: "LiveBoardHomepageFirstFoldComponent",
        title: "Live Board",
        imgSrc: "/static/img/segments/layouts/live-board-homepage-first-fold.png",
        config: LiveBoardHomepageFirstFold.LiveBoardHomepageFirstFoldConfig,
        advancedConfig: LiveBoardHomepageFirstFold.LiveBoardHomepageFirstFoldAdvancedConfig,
        component: LiveBoardHomepageFirstFold.LiveBoardHomepageFirstFoldComponent
    },
    SignUpWithProductsCardsHomepageFirstFoldComponent: {
        type: "SignUpWithProductsCardsHomepageFirstFoldComponent",
        title: "Sign Up With Products Cards",
        imgSrc: "/static/img/segments/layouts/sign-up-with-products-cards-homepage-first-fold.png",
        config: SignUpWithProductsCardsHomepageFirstFold.SignUpWithProductsCardsHomepageFirstFoldConfig,
        advancedConfig: SignUpWithProductsCardsHomepageFirstFold.SignUpWithProductsCardsHomepageFirstFoldAdvancedConfig,
        component: SignUpWithProductsCardsHomepageFirstFold.SignUpWithProductsCardsHomepageFirstFoldComponent
    },
    WelcomeBackHomepageFirstFoldComponent: {
        type: "WelcomeBackHomepageFirstFoldComponent",
        title: "Welcome Back",
        imgSrc: "/static/img/segments/layouts/welcome-back-homepage-first-fold.png",
        config: WelcomeBackHomepageFirstFold.WelcomeBackHomepageFirstFoldConfig,
        advancedConfig: WelcomeBackHomepageFirstFold.WelcomeBackHomepageFirstFoldAdvancedConfig,
        component: WelcomeBackHomepageFirstFold.WelcomeBackHomepageFirstFoldComponent
    },
    MondayUniverseHomepageFirstFoldComponent: {
        type: "MondayUniverseHomepageFirstFoldComponent",
        title: "Monday Universe",
        imgSrc: "/static/img/segments/layouts/monday-universe-homepage-first-fold.png",
        config: MondayUniverseHomepageFirstFold.MondayUniverseHomepageFirstFoldConfig,
        advancedConfig: MondayUniverseHomepageFirstFold.MondayUniverseHomepageFirstFoldAdvancedConfig,
        component: MondayUniverseHomepageFirstFold.MondayUniverseHomepageFirstFoldComponent
    },
    GlobeWithTopCtaHomepageFirstFoldComponent: {
        type: "GlobeWithTopCtaHomepageFirstFoldComponent",
        title: "Globe With Top Cta",
        imgSrc: "/static/img/segments/layouts/globe-with-top-cta-homepage-first-fold.png",
        config: GlobeWithTopCtaHomepageFirstFold.GlobeWithTopCtaHomepageFirstFoldConfig,
        advancedConfig: GlobeWithTopCtaHomepageFirstFold.GlobeWithTopCtaHomepageFirstFoldAdvancedConfig,
        component: GlobeWithTopCtaHomepageFirstFold.GlobeWithTopCtaHomepageFirstFoldComponent
    },
    GlobeHomepageFirstFoldComponent: {
        type: "GlobeHomepageFirstFoldComponent",
        title: "Globe",
        imgSrc: "/static/img/segments/layouts/globe-homepage-first-fold.png",
        config: GlobeHomepageFirstFold.GlobeHomepageFirstFoldConfig,
        advancedConfig: GlobeHomepageFirstFold.GlobeHomepageFirstFoldAdvancedConfig,
        component: GlobeHomepageFirstFold.GlobeHomepageFirstFoldComponent
    },
    AnimatingBoardHomepageFirstFoldComponent: {
        type: "AnimatingBoardHomepageFirstFoldComponent",
        title: "Animating Board",
        imgSrc: "/static/img/segments/layouts/animating-board-homepage-first-fold.png",
        config: AnimatingBoardHomepageFirstFold.AnimatingBoardHomepageFirstFoldConfig,
        advancedConfig: AnimatingBoardHomepageFirstFold.AnimatingBoardHomepageFirstFoldAdvancedConfig,
        component: AnimatingBoardHomepageFirstFold.AnimatingBoardHomepageFirstFoldComponent
    },
    ScrollParallaxHomepageFirstFoldComponent: {
        type: "ScrollParallaxHomepageFirstFoldComponent",
        title: "Scroll Parallax",
        imgSrc: "/static/img/segments/layouts/scroll-parallax-homepage-first-fold.png",
        config: ScrollParallaxHomepageFirstFold.ScrollParallaxHomepageFirstFoldConfig,
        advancedConfig: ScrollParallaxHomepageFirstFold.ScrollParallaxHomepageFirstFoldAdvancedConfig,
        component: ScrollParallaxHomepageFirstFold.ScrollParallaxHomepageFirstFoldComponent
    }
};
