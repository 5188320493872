import { FIELD_TYPES } from "constants/template-field-type";
export default {
    tagImg: {
        type: FIELD_TYPES.NESTED,
        label: "Image Tag",
        fields: {
            src: {
                type: FIELD_TYPES.GALLERY,
                defaultImagesKeys: [],
                label: "Select a Tag Image"
            }
        }
    },
    link: {
        type: FIELD_TYPES.TEXT,
        label: "Link"
    },
    tagText: {
        type: FIELD_TYPES.TEXT,
        label: "Tag Text",
        translatable: true
    }
};
