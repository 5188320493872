function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import FullWithBulletsParagraphConfig from "segments/desktop/paragraph/layouts/full-with-bullets-paragraph/full-with-bullets-paragraph-config";
import FullWithBulletsParagraphAdvancedConfig from "segments/desktop/paragraph/layouts/full-with-bullets-paragraph/full-with-bullets-paragraph-advanced-config";
import TitleAdvancedConfig from "segments/desktop/core-components/title/advanced-field-config";
const { bulletsConfig } = FullWithBulletsParagraphConfig.fields;
const _FullWithBulletsParagraphAdvancedConfig_fields = FullWithBulletsParagraphAdvancedConfig.fields, { topTriangle, bottomTriangle, segmentId, segmentMinHeight } = _FullWithBulletsParagraphAdvancedConfig_fields, restOfParagraph = _object_without_properties(_FullWithBulletsParagraphAdvancedConfig_fields, [
    "topTriangle",
    "bottomTriangle",
    "segmentId",
    "segmentMinHeight"
]);
export default {
    type: FIELD_TYPES.NESTED,
    label: "",
    fields: _object_spread_props(_object_spread({}, SharedAdvancedFieldsConfig), {
        parallaxSpeed: {
            type: FIELD_TYPES.TEXT,
            label: "Parallax speed"
        },
        tagsTitleAdvancedConfig: {
            type: FIELD_TYPES.NESTED,
            label: "Tags title",
            fields: TitleAdvancedConfig
        },
        tagsVerticalPadding: {
            type: FIELD_TYPES.TEXT,
            label: "Tags vertical padding"
        },
        hidePanel: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Hide upper tags panel"
        },
        tagsFontSize: {
            type: FIELD_TYPES.COMBO,
            label: "Tags text size",
            options: [
                {
                    value: "0.8125rem",
                    text: "13px"
                },
                {
                    value: "0.875rem",
                    text: "14px"
                }
            ]
        },
        productAdvanceConfig: {
            type: FIELD_TYPES.NESTED,
            label: "Product advanced config",
            fields: {
                productDetailsVerticalPadding: {
                    type: FIELD_TYPES.TEXT,
                    label: "Product details left padding"
                },
                useCasesAdvancedConfig: {
                    type: FIELD_TYPES.NESTED,
                    label: "Product use cases",
                    fields: {
                        horizontalPadding: {
                            type: FIELD_TYPES.TEXT,
                            label: "Horizontal padding"
                        },
                        productParagraph: {
                            type: FIELD_TYPES.NESTED,
                            label: "Product paragraph",
                            fields: restOfParagraph
                        },
                        bulletTextSize: bulletsConfig.fields.bulletTextSize
                    }
                }
            }
        }
    })
};
